import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import HomeIcon from "@mui/icons-material/Home";
import {
  ThemeProvider,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import moment from "moment";
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  newCheckoutMonthly,
  newCheckoutAnnual,
  getProductPrices,
} from "../../api/premium";
import { getUser, getUserSubscriptionsFreeTrial } from "../../api/user";
import "./premium.css";
import { config } from "../../config";
import {
  CheckPremium,
  WelcomeVisual,
  WelcomeVisualXL,
} from "../../styles/Icons";
import { Theme } from "../../styles/styles";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";

class Premium extends React.Component {
  constructor(properties) {
    super(properties);
    let userdata = {};
    let connected = false;
    if (
      properties.queryUser.isSuccess &&
      this.props.queryUser.data !== undefined &&
      this.props.queryUser.data.length > 0
    ) {
      userdata = {
        userId: this.props.queryUser.data[0].userId,
        pro: this.props.queryUser.data[0].proSante === "true" ? true : false,
        proLabel: this.props.queryUser.data[0].proSanteLabel,
        email: this.props.queryUser.data[0].email,
        periode: this.props.queryUser.data[0].periode,
        dateFin: this.props.queryUser.data[0].dateFin,
      };
      connected = true;
    }
    this.state = {
      activeOffer: 0,
      premiumFreeTrial: false,
      tabOpen: false,
      userdata,
      connected,
      acceptCGV: false,
      subscriptionDetails: {
        yearly: { price: null, currency: null },
        monthly: { price: null, currency: null },
      },
      loading: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  componentDidMount() {
    this.props.queryUser.refetch();
    this.props.mutationAbonnementPrice.mutate();
    this.props.mutationUserSubscriptionsFreeTrial.mutate();
  }

  componentDidUpdate(pP, pS) {
    if (
      !pS.connected &&
      this.props.queryUser.data !== undefined &&
      this.props.queryUser.data[0] !== undefined
    ) {
      if (moment(this.props.queryUser.data[0].dateFin).isAfter()) {
        this.props.navigation("/");
      } else {
        this.setState({
          connected: true,
          userdata: {
            userId: this.props.queryUser.data[0].userId,
            pro:
              this.props.queryUser.data[0].proSante === "true" ? true : false,
            proLabel: this.props.queryUser.data[0].proSanteLabel,
            email: this.props.queryUser.data[0].email,
            periode: this.props.queryUser.data[0].periode,
            dateFin: this.props.queryUser.data[0].dateFin,
          },
        });
      }
    }
    if (this.props.mutationAbonnementPrice.isLoading && !this.state.loading) {
      this.setState({ loading: true });
    }
    if (
      this.props.mutationAbonnementPrice.isSuccess &&
      !this.state.subscriptionDetails.yearly.price &&
      !this.state.subscriptionDetails.monthly.price &&
      !this.state.subscriptionDetails.yearly.currency &&
      !this.state.subscriptionDetails.monthly.currency
    ) {
      const subscriptionPriceData = this.props.mutationAbonnementPrice.data;
      this.setState({
        loading: false,
        subscriptionDetails: {
          yearly: {
            price: subscriptionPriceData.yearly.price,
            currency: subscriptionPriceData.yearly.currency,
          },
          monthly: {
            price: subscriptionPriceData.monthly.price,
            currency: subscriptionPriceData.monthly.currency,
          },
        },
      });
    }
    if (this.props.mutationAbonnementPrice.isError && this.state.loading) {
      this.setState({
        loading: false,
        subscriptionDetails: {
          yearly: {
            price: config.premium.yearly.price,
            currency: config.premium.yearly.currency,
          },
          monthly: {
            price: config.premium.monthly.price,
            currency: config.premium.monthly.currency,
          },
        },
      });
    }
    if (
      this.props.mutationAbonnementMensuel.isLoading &&
      !this.props.mutationAbonnementAnnuel.isLoading &&
      !this.state.tabOpen
    ) {
      this.setState({ tabOpen: true });
    }
    if (
      this.props.mutationAbonnementMensuel.isSuccess &&
      !this.props.mutationAbonnementAnnuel.isLoading &&
      this.state.tabOpen
    ) {
      window.open(this.props.mutationAbonnementMensuel.data);
      this.setState({ tabOpen: false });
    }
    if (
      this.props.mutationAbonnementAnnuel.isLoading &&
      !this.props.mutationAbonnementMensuel.isLoading &&
      !this.state.tabOpen
    ) {
      this.setState({ tabOpen: true });
    }
    if (
      this.props.mutationAbonnementAnnuel.isSuccess &&
      !this.props.mutationAbonnementMensuel.isLoading &&
      this.state.tabOpen
    ) {
      window.open(this.props.mutationAbonnementAnnuel.data);
      this.setState({ tabOpen: false });
    }
    if (
      this.props.mutationUserSubscriptionsFreeTrial.isSuccess &&
      this.props.mutationUserSubscriptionsFreeTrial.data.hasTrial !==
        this.state.premiumFreeTrial
    ) {
      this.setState({
        premiumFreeTrial:
          this.props.mutationUserSubscriptionsFreeTrial.data.hasTrial,
      });
    }
  }

  handleSelect(i) {
    this.setState({ activeOffer: i, tabOpen: false });
  }

  handleCheckout() {
    const { activeOffer } = this.state;
    if (activeOffer === 1) {
      this.props.mutationAbonnementMensuel.mutate();
    } else if (activeOffer === 2) {
      this.props.mutationAbonnementAnnuel.mutate();
    }
  }

  handleCheckBox() {
    this.setState({ acceptCGV: !this.state.acceptCGV });
  }

  render() {
    const { t } = this.props;

    const premiumServices = [
      { label: t("premium.service.use") },
      { label: t("premium.service.test") },
      { label: t("premium.service.search") },
    ];

    const {
      activeOffer,
      connected,
      acceptCGV,
      subscriptionDetails,
      loading,
      premiumFreeTrial,
    } = this.state;

    const monthlyCurrency = getCurrencySymbol(
      subscriptionDetails.monthly.currency
    );
    const yearlyCurrency = getCurrencySymbol(
      subscriptionDetails.yearly.currency
    );

    return (
      <ThemeProvider theme={Theme}>
        <div className="mainApp premiumApp">
          <div className="premiumHeader">
            <Button
              disabled
              sx={{
                color: "white !important",
                background: "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                textTransform: "unset !important",
                fontSize: "10px",
              }}
            >
              <AutoAwesomeRoundedIcon fontSize="12px" color="white" />
              {" Vigicare Premium"}
            </Button>
            <Link to="/">
              <HomeIcon color="black" />
            </Link>
          </div>
          <h1>{t("premium.title")}</h1>
          <div className="premiumVisual">
            <WelcomeVisual />
          </div>
          <div className="premiumVisualXL">
            <WelcomeVisualXL />
          </div>
          <div className="premiumInfo">
            <div className="premiumOffers">
              {config.premium.monthly.enabled && (
                <div
                  onClick={() => this.handleSelect(1)}
                  className={`premiumScreenBox premiumScreenBoxMonth ${
                    activeOffer === 1 ? "selectedBox " : ""
                  } ${!premiumFreeTrial ? "premiumScreenBoxTrial" : ""} ${
                    i18n.language === "fr" ? "boxSmall" : ""
                  }`.trimEnd()}
                >
                  <p className="premiumTitle">
                    {t("premium.subscription.monthly.title")}
                  </p>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ display: "block", margin: "auto" }}
                    />
                  ) : (
                    <>
                      <p className="price">
                        <Trans
                          i18nKey="premium:premium.subscription.monthly.price"
                          values={{
                            month: subscriptionDetails.monthly.price,
                            currency: monthlyCurrency,
                          }}
                          components={{ 1: <b></b> }}
                        ></Trans>
                      </p>
                      <p>{t("premium.subscription.monthly.info")}</p>
                    </>
                  )}
                </div>
              )}
              {config.premium.yearly.enabled && (
                <div
                  onClick={() => this.handleSelect(2)}
                  className={`premiumScreenBox premiumScreenBoxAnnual ${
                    !premiumFreeTrial ? "premiumScreenBoxTrial" : ""
                  } ${activeOffer === 2 ? "selectedBox " : ""} ${
                    i18n.language === "fr" ? "boxSmall" : ""
                  }`.trimEnd()}
                >
                  <span className="premiumHelperText">
                    {t("premium.subscription.annual.tag")}
                  </span>

                  <p className="premiumTitle">
                    {t("premium.subscription.annual.title")}
                  </p>
                  {!premiumFreeTrial && (
                    <p>{t("premium.subscription.annual.trial")}</p>
                  )}
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ display: "block", margin: "0.5rem auto" }}
                    />
                  ) : (
                    <>
                      <p className="price">
                        <Trans
                          i18nKey="premium:premium.subscription.annual.price"
                          values={{
                            year: subscriptionDetails.yearly.price,
                            currency: yearlyCurrency,
                          }}
                          components={{ 1: <b></b> }}
                        ></Trans>
                      </p>
                      <p>{t("premium.subscription.annual.info")}</p>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="premiumFeature">
              {premiumServices.map((service, index) => (
                <div key={index}>
                  <div>
                    <CheckPremium className="iconPremium" />
                  </div>
                  <p>{service.label}</p>
                </div>
              ))}
            </div>
            {!connected ? (
              <>
                <Link to="/connexion">
                  <Button
                    sx={{
                      color: "#ffffff",
                      background: "#F46B45 !important",
                      textTransform: "unset !important",
                      width: "100%",
                    }}
                  >
                    {t("premium.action.connect")}
                  </Button>
                </Link>
                <p className="loginMessage">{t("premium.loginMessage")}</p>
              </>
            ) : (
              <>
                <div className="acceptCGV" onClick={this.handleCheckBox}>
                  <Checkbox checked={acceptCGV} id="acceptCGV" />
                  <p id="acceptCGV">
                    <Trans
                      i18nKey="premium:premium.termsAndConditions"
                      components={{ 1: <a href="/cgu" target="_blank"></a> }}
                    ></Trans>
                  </p>
                </div>
                <div className="subscribeButton">
                  {activeOffer === 0 || !acceptCGV || loading ? (
                    <Button
                      sx={{
                        background: "#E9E9E9",
                        textTransform: "unset !important",
                      }}
                      disabled
                    >
                      {t("premium.action.subscribe")}
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        color: "#ffffff",
                        background: "#F46B45 !important",
                        textTransform: "unset !important",
                      }}
                      onClick={this.handleCheckout}
                    >
                      {t("premium.action.subscribe")}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation("premium")(Premium)));

function withRouter(Child) {
  return (properties) => {
    const navig = useNavigate();
    return <Child {...properties} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );

    const mutationAbonnementMensuel = useMutation({
      mutationFn: () => {
        return newCheckoutMonthly()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationAbonnementAnnuel = useMutation({
      mutationFn: () => {
        return newCheckoutAnnual()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationAbonnementPrice = useMutation({
      mutationFn: () => {
        return getProductPrices()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationUserSubscriptionsFreeTrial = useMutation({
      mutationFn: () => {
        return getUserSubscriptionsFreeTrial()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    return (
      <Child
        {...properties}
        mutationAbonnementMensuel={mutationAbonnementMensuel}
        mutationAbonnementAnnuel={mutationAbonnementAnnuel}
        queryUser={queryUser}
        mutationAbonnementPrice={mutationAbonnementPrice}
        mutationUserSubscriptionsFreeTrial={mutationUserSubscriptionsFreeTrial}
      />
    );
  };
}
