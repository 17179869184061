import { Button, TextField, MenuItem, Grid, Box, Radio } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";

export default class Utilisateur extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          name: properties.data.name ? properties.data.name : "",
          surname: properties.data.surname ? properties.data.surname : "",
          sex: properties.data.sex ? properties.data.sex : "",
          birthdate: properties.data.birthdate ? properties.data.birthdate : "",
          profession: properties.data.profession
            ? properties.data.profession
            : "",
          pregnancy: properties.data.pregnancy
            ? properties.data.pregnancy
            : null,
        },
        errorIncomplete: false,
      };
    } else {
      this.state = {
        data: {
          name: "",
          surname: "",
          sex: "",
          birthdate: null,
          profession: "",
          pregnancy: null,
        },
        errorIncomplete: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDate(event_) {
    this.setState({ data: { ...this.state.data, birthdate: event_ } });
  }

  handleChangeDropdown(event_) {
    if (event_.target.value !== undefined)
      this.setState({ data: { ...this.state.data, sex: event_.target.value } });
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "name":
        this.setState({
          data: { ...this.state.data, name: event_.target.value },
        });
        break;
      case "surname":
        this.setState({
          data: { ...this.state.data, surname: event_.target.value },
        });
        break;
      case "profession":
        this.setState({
          data: { ...this.state.data, profession: event_.target.value },
        });
        break;
      case "pregnancyTrue":
        this.setState({ data: { ...data, pregnancy: true } });
        break;
      case "pregnancyFalse":
        this.setState({ data: { ...data, pregnancy: false } });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    const { data } = this.state;
    if (
      data.name.match(/^[A-Za-z]{2,}$/) !== null &&
      data.surname.match(/^[A-Za-z]{2,}$/) !== null &&
      data.sex !== "" &&
      data.birthdate !== null &&
      data.birthdate.isValid() &&
      data.birthdate.isBefore()
    ) {
      this.props.handler(this.state.data);
    } else {
      this.setState({ errorIncomplete: true });
    }
  }

  render() {
    const { data, errorIncomplete } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <Grid>
        <p className="pageTitle">Utilisateur</p>

        <Grid className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </Grid>
        {errorIncomplete ? (
          <p className="errorText">Champs manquants ou incorrects</p>
        ) : null}

        <form className="inputForm">
          <TextField
            id="name"
            type="text"
            value={data.name}
            onChange={this.handleChange}
            label="Nom * (minimum 2 lettres)"
            error={
              errorIncomplete && data.name.match(/^[A-Za-z]{2,}$/) === null
            }
          />
          <TextField
            id="surname"
            type="text"
            value={data.surname}
            onChange={this.handleChange}
            label="Prénom * (minimum 2 lettres)"
            error={
              errorIncomplete && data.surname.match(/^[A-Za-z]{2,}$/) === null
            }
          />
          <DesktopDatePicker
            id="birthdate"
            inputFormat="DD/MM/YYYY"
            value={data.birthdate}
            onChange={this.handleChangeDate}
            label="Date de naissance *"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={
                    (errorIncomplete && data.birthdate === null) ||
                    (errorIncomplete &&
                      data.birthdate !== null &&
                      (!data.birthdate.isValid() || !data.birthdate.isBefore()))
                  }
                />
              );
            }}
          />
          <TextField
            select
            id="sex"
            className="inputDropdown"
            value={data.sex}
            onChange={this.handleChangeDropdown}
            label="Sexe *"
            error={errorIncomplete && data.sex === ""}
          >
            <MenuItem value={"Femme"}>Femme</MenuItem>
            <MenuItem value={"Homme"}>Homme</MenuItem>
          </TextField>
          {data.sex === "Femme" ? (
            <>
              <p className="robotoFontB">Grossesse :</p>
              <Grid className="inputRadioRow">
                <label className="robotoFont">
                  <Radio
                    id="pregnancyTrue"
                    checked={data.pregnancy === true}
                    onChange={this.handleChange}
                  />
                  {"  Oui"}
                </label>
                <label className="robotoFont">
                  <Radio
                    id="pregnancyFalse"
                    checked={data.pregnancy === false}
                    onChange={this.handleChange}
                  />
                  {"  Non"}
                </label>
              </Grid>
            </>
          ) : null}
          <TextField
            fullWidth
            id="profession"
            type="text"
            value={data.profession}
            onChange={this.handleChange}
            label="Profession"
          />
        </form>
        <p className="requiredMessage">
          <Trans>* Champs obligatoires</Trans>
        </p>

        <Box className="bottomBtnForm">
          <Button
            variant="contained"
            color="blue"
            onClick={() => this.onSubmit()}
          >
            {"SUIVANT"}
          </Button>
        </Box>
      </Grid>
    );
  }
}
