import {
  Grid,
  Button,
  Checkbox,
  TextField,
  Radio,
  MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";

export default class DescEffets extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          dateDebut:
            properties.data.dateDebut !== undefined
              ? properties.data.dateDebut
              : null,
          delai:
            properties.data.delai !== undefined ? properties.data.delai : "",
          uniteDuree:
            properties.data.uniteDuree !== undefined
              ? properties.data.uniteDuree
              : "Jours",
          symptomes:
            properties.data.symptomes !== undefined
              ? properties.data.symptomes
              : "",
          examens:
            properties.data.examens !== undefined
              ? properties.data.examens
              : "",
          priseCharge:
            properties.data.priseCharge !== undefined
              ? properties.data.priseCharge
              : "",
          traitement:
            properties.data.traitement !== undefined
              ? properties.data.traitement
              : null,
          traitementText:
            properties.data.traitementText !== undefined
              ? properties.data.traitementText
              : "",
          evolution:
            properties.data.evolution !== undefined
              ? properties.data.evolution
              : "",
          evolutionChrono:
            properties.data.evolutionChrono !== undefined
              ? properties.data.evolutionChrono
              : "",
        },
        errorIncomplete: false,
      };
    } else {
      this.state = {
        data: {
          dateDebut: null,
          delai: "",
          uniteDuree: "Jours",
          symptomes: "",
          examens: "",
          priseCharge: "",
          traitement: null,
          traitementText: "",
          evolution: "",
          evolutionChrono: "",
        },
        errorIncomplete: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDropdown(event_) {
    if (event_.target.value !== undefined) {
      this.setState({
        data: { ...this.state.data, uniteDuree: event_.target.value },
      });
    }
  }

  handleChangeDate(event_) {
    this.setState({ data: { ...this.state.data, dateDebut: event_ } });
  }

  handleChange(event_) {
    const { data } = this.state;
    if (event_ !== undefined) {
      switch (event_.target.id) {
        case "delai":
          this.setState({ data: { ...data, delai: event_.target.value } });
          break;
        case "symptomes":
          this.setState({ data: { ...data, symptomes: event_.target.value } });
          break;
        case "examens":
          this.setState({ data: { ...data, examens: event_.target.value } });
          break;
        case "pecNon":
          this.setState({ data: { ...data, priseCharge: "non" } });
          break;
        case "pecMed":
          this.setState({ data: { ...data, priseCharge: "medecin" } });
          break;
        case "pecU":
          this.setState({ data: { ...data, priseCharge: "urgences" } });
          break;
        case "pecH":
          this.setState({ data: { ...data, priseCharge: "hospitalisation" } });
          break;
        case "traitementTrue":
          this.setState({ data: { ...data, traitement: true } });
          break;
        case "traitementFalse":
          this.setState({ data: { ...data, traitement: false } });
          break;
        case "traitementNSP":
          this.setState({ data: { ...data, traitement: null } });
          break;
        case "traitementText":
          this.setState({
            data: { ...data, traitementText: event_.target.value },
          });
          break;
        case "evoGen":
          this.setState({ data: { ...data, evolution: "general" } });
          break;
        case "evoEnCours":
          this.setState({ data: { ...data, evolution: "enCours" } });
          break;
        case "evoNon":
          this.setState({ data: { ...data, evolution: "non" } });
          break;
        case "evoAgg":
          this.setState({ data: { ...data, evolution: "aggravation" } });
          break;
        case "evoChrono":
          this.setState({
            data: { ...data, evolutionChrono: event_.target.value },
          });
          break;
        default:
          break;
      }
    }
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">{"Description des effets"}</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <div className="inputForm">
          <DesktopDatePicker
            id="dateDebut"
            inputFormat="DD/MM/YYYY"
            value={data.dateDebut}
            onChange={this.handleChangeDate}
            label="Date d'apparition des premiers effets *"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={
                    data.dateDebut !== null &&
                    (!data.dateDebut.isBefore() || !data.dateDebut.isValid())
                  }
                />
              );
            }}
          />
          <Grid className="formRowDouble">
            <TextField
              fullWidth
              id="delai"
              type="number"
              value={data.delai}
              onChange={this.handleChange}
              label="Délai d'apparition"
            />
            <TextField
              select
              value={data.uniteDuree}
              onChange={this.handleChangeDropdown}
              sx={{ minWidth: "35%", textAlign: "end" }}
            >
              <MenuItem value="Jours">Jours</MenuItem>
              <MenuItem value="Semaines">Semaines</MenuItem>
              <MenuItem value="Années">Années</MenuItem>
            </TextField>
          </Grid>

          <TextField
            id="symptomes"
            multiline
            rows={6}
            type="text"
            value={data.symptomes}
            onChange={this.handleChange}
            label="Description des symptômes *"
          />
          <TextField
            id="examens"
            multiline
            rows={6}
            type="text"
            value={data.examens}
            onChange={this.handleChange}
            label="Examens réalisés *"
          />

          <Grid>
            <p className="robotoFontB">Prise en charge médicale *</p>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="pecNon"
                  checked={data.priseCharge === "non"}
                  onChange={this.handleChange}
                />
                {"  Aucune"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="pecMed"
                  checked={data.priseCharge === "medecin"}
                  onChange={this.handleChange}
                />
                {"  Medecin"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="pecU"
                  checked={data.priseCharge === "urgences"}
                  onChange={this.handleChange}
                />
                {"  Urgences"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="pecH"
                  checked={data.priseCharge === "hospitalisation"}
                  onChange={this.handleChange}
                />
                {"  Hospitalisation"}
              </label>
            </Grid>
          </Grid>

          <Grid>
            <p className="robotoFontB">Mise en place d'un traitement</p>
            <Grid className="inputRadioRow">
              <label className="robotoFont">
                <Radio
                  id="traitementTrue"
                  checked={data.traitement === true}
                  onChange={this.handleChange}
                />
                {"  Oui"}
              </label>
              <label className="robotoFont">
                <Radio
                  id="traitementFalse"
                  checked={data.traitement === false}
                  onChange={this.handleChange}
                />
                {"  Non"}
              </label>
              <label className="robotoFont">
                <Radio
                  id="traitementNSP"
                  checked={data.traitement === null}
                  onChange={this.handleChange}
                />
                {"  Je ne sais pas"}
              </label>
            </Grid>
            <TextField
              fullWidth
              disabled={data.traitement !== true}
              id="traitementText"
              type="text"
              value={data.traitementText}
              onChange={this.handleChange}
              label="Si oui, précisez"
            />
          </Grid>

          <Grid>
            <p className="robotoFontB">Évolution</p>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="evoGen"
                  checked={data.evolution === "general"}
                  onChange={this.handleChange}
                />
                {"  Guérison générale"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="evoEnCours"
                  checked={data.evolution === "enCours"}
                  onChange={this.handleChange}
                />
                {"  Guérison en cours"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="evoNon"
                  checked={data.evolution === "non"}
                  onChange={this.handleChange}
                />
                {"  Pas de guérison"}
              </label>
            </Grid>
            <Grid className="inputCheckboxRow">
              <label className="robotoFont">
                <Checkbox
                  id="evoAgg"
                  checked={data.evolution === "aggravation"}
                  onChange={this.handleChange}
                />
                {"  Aggravation de l'état"}
              </label>
            </Grid>
            <TextField
              fullWidth
              id="evoChrono"
              type="text"
              value={data.evolutionChrono}
              onChange={this.handleChange}
              label="Précisez la chronologie de l'évolution"
            />
          </Grid>
          <p className="requiredMessage">
            <Trans>* Champs obligatoires</Trans>
          </p>

          {data.symptomes !== "" &&
          data.dateDebut !== null &&
          data.dateDebut.isBefore() &&
          data.dateDebut.isValid() &&
          data.examens !== "" &&
          data.priseCharge !== "" ? (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.onSubmit()}
              >
                {"SUIVANT"}
              </Button>
            </Grid>
          ) : (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button variant="contained" disabled>
                {"SUIVANT"}
              </Button>
            </Grid>
          )}
        </div>
      </>
    );
  }
}
