import { Grid, Button, TextField, Radio, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";

export default class Consommateur extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          nom: properties.data.nom !== undefined ? properties.data.nom : "",
          prenom:
            properties.data.prenom !== undefined ? properties.data.prenom : "",
          birthdate:
            properties.data.birthdate !== undefined
              ? properties.data.birthdate
              : null,
          sex: properties.data.sex !== undefined ? properties.data.sex : "",
          pregnancy:
            properties.data.pregnancy !== undefined
              ? properties.data.pregnancy
              : null,
          weigth:
            properties.data.weigth !== undefined ? properties.data.weigth : "",
          height:
            properties.data.height !== undefined ? properties.data.height : "",
          profession:
            properties.data.profession !== undefined
              ? properties.data.profession
              : "",
          antecedents:
            properties.data.antecedents !== undefined
              ? properties.data.antecedents
              : null,
          antecedentsText:
            properties.data.antecedentsText !== undefined
              ? properties.data.antecedentsText
              : "",
        },
        errorIncomplete: false,
      };
    } else {
      this.state = {
        data: {
          nom: "",
          prenom: "",
          birthdate: null,
          sex: "",
          pregnancy: null,
          weigth: "",
          height: "",
          profession: "",
          antecedents: null,
          antecedentsText: "",
        },
        errorIncomplete: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDate(event_) {
    this.setState({
      data: { ...this.state.data, birthdate: event_ },
      errorIncomplete: false,
    });
  }

  handleChangeDropdown(event_) {
    if (event_.target.value !== undefined)
      this.setState({ data: { ...this.state.data, sex: event_.target.value } });
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "nom":
        this.setState({ data: { ...data, nom: event_.target.value } });
        break;
      case "prenom":
        this.setState({ data: { ...data, prenom: event_.target.value } });
        break;
      case "birthdate":
        this.setState({ data: { ...data, birthdate: event_.target.value } });
        break;
      case "weigth":
        this.setState({ data: { ...data, weigth: event_.target.value } });
        break;
      case "height":
        this.setState({ data: { ...data, height: event_.target.value } });
        break;
      case "profession":
        this.setState({ data: { ...data, profession: event_.target.value } });
        break;
      case "antecedentsText":
        this.setState({
          data: { ...data, antecedentsText: event_.target.value },
        });
        break;

      case "trueP":
        this.setState({ data: { ...data, pregnancy: true } });
        break;
      case "falseP":
        this.setState({ data: { ...data, pregnancy: false } });
        break;
      case "nullP":
        this.setState({ data: { ...data, pregnancy: null } });
        break;
      case "nullA":
        this.setState({ data: { ...data, antecedents: null } });
        break;
      case "trueA":
        this.setState({ data: { ...data, antecedents: true } });
        break;
      case "falseA":
        this.setState({ data: { ...data, antecedents: false } });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    const { data } = this.state;
    if (
      data.nom.match(/^[A-Za-z]{2,}$/) !== null &&
      (!data.antecedents || data.antecedentsText !== "")
    ) {
      if (data.birthdate !== null) {
        if (data.birthdate.isValid() && data.birthdate.isBefore()) {
          this.props.handler(this.state.data);
        } else {
          window.scrollTo(0, 0);
          this.setState({ errorIncomplete: true });
        }
      } else this.props.handler(this.state.data);
    } else {
      window.scrollTo(0, 0);
      this.setState({ errorIncomplete: true });
    }
  }

  render() {
    const { data, errorIncomplete } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">Consommateur</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <p>Données relatives au consommateur :</p>
        {errorIncomplete ? (
          <p className="errorText">Champs manquants ou incorrects</p>
        ) : null}

        <div className="inputForm">
          <TextField
            fullWidth
            id="nom"
            type="text"
            value={data.nom}
            onChange={this.handleChange}
            label="Nom * (minimum 2 lettres)"
            error={errorIncomplete && data.nom.match(/^[A-Za-z]{2,}$/) === null}
          />
          <TextField
            fullWidth
            id="prenom"
            type="text"
            value={data.prenom}
            onChange={this.handleChange}
            label="Prénom"
          />
          <DesktopDatePicker
            fullWidth
            id="birthdate"
            inputFormat="DD/MM/YYYY"
            value={data.birthdate}
            onChange={this.handleChangeDate}
            label="Date de naissance"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={
                    data.birthdate !== null &&
                    (!data.birthdate.isBefore() || !data.birthdate.isValid())
                  }
                />
              );
            }}
          />
          <TextField
            select
            id="sex"
            className="inputDropdown"
            value={data.sex}
            onChange={this.handleChangeDropdown}
            label="Sexe"
          >
            <MenuItem value={"Femme"}>Femme</MenuItem>
            <MenuItem value={"Homme"}>Homme</MenuItem>
          </TextField>

          {data.sex === "Femme" ? (
            <Grid>
              <p className="robotoFontB">Grossesse en cours</p>
              <Grid className="inputRadioRow">
                <label className="robotoFont">
                  <Radio
                    id="trueP"
                    checked={data.pregnancy === true}
                    onChange={this.handleChange}
                  />
                  {"  Oui"}
                </label>
                <label className="robotoFont">
                  <Radio
                    id="falseP"
                    checked={data.pregnancy === false}
                    onChange={this.handleChange}
                  />
                  {"  Non"}
                </label>
                <label className="robotoFont">
                  <Radio
                    id="nullP"
                    checked={data.pregnancy === null}
                    onChange={this.handleChange}
                  />
                  {"  Je ne sais pas"}
                </label>
              </Grid>
            </Grid>
          ) : null}

          <div className="inputSmallRow">
            <TextField
              id="weigth"
              className="inputSmall"
              type="text"
              value={data.weigth}
              onChange={this.handleChange}
              label="Poids (en kg)"
            />
            <TextField
              id="height"
              className="inputSmall"
              type="text"
              value={data.height}
              onChange={this.handleChange}
              label="Taille (en m)"
            />
          </div>

          <TextField
            fullWidth
            id="profession"
            type="text"
            value={data.profession}
            onChange={this.handleChange}
            label="Profession"
          />

          <Grid>
            <p className="robotoFontB">
              Le consommateur a-t-il des antécédents ?
            </p>
            <Grid className="inputRadioRow">
              <label className="robotoFont">
                <Radio
                  id="trueA"
                  checked={data.antecedents === true}
                  onChange={this.handleChange}
                />
                {"  Oui"}
              </label>
              <label className="robotoFont">
                <Radio
                  id="falseA"
                  checked={data.antecedents === false}
                  onChange={this.handleChange}
                />
                {"  Non"}
              </label>
              <label className="robotoFont">
                <Radio
                  id="nullA"
                  checked={data.antecedents === null}
                  onChange={this.handleChange}
                />
                {"  Je ne sais pas"}
              </label>
            </Grid>
            {data.antecedents === true ? (
              <TextField
                fullWidth
                id="antecedentsText"
                type="text"
                value={data.antecedentsText}
                onChange={this.handleChange}
                label="Si oui, précisez lesquels"
                helperText={
                  errorIncomplete &&
                  data.antecedents &&
                  data.antecedentsText === ""
                    ? "Donnée manquante"
                    : null
                }
                error={errorIncomplete && data.antecedentsText === ""}
              />
            ) : null}
          </Grid>
        </div>
        <p className="requiredMessage">
          <Trans>* Champs obligatoires</Trans>
        </p>
        <Grid textAlign={"end"} marginBottom="24px" marginTop="24px">
          <Button
            variant="contained"
            color="blue"
            onClick={() => this.onSubmit()}
          >
            {"SUIVANT"}
          </Button>
        </Grid>
      </>
    );
  }
}
