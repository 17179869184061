import { Checkbox, Grid, Button, Box } from "@mui/material";
import React from "react";

export default class Gravite extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          hospitalisation: properties.data.hospitalisation,
          incapacite: properties.data.incapacite,
          pronosticVital: properties.data.pronosticVital,
          deces: properties.data.deces,
          situationGrave: properties.data.situationGrave,
          anomalies: properties.data.anomalies,
          aucun: properties.data.aucun,
        },
      };
    } else {
      this.state = {
        data: {
          hospitalisation: false,
          incapacite: false,
          pronosticVital: false,
          deces: false,
          situationGrave: false,
          anomalies: false,
          aucun: false,
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "hospitalisation":
        this.setState({
          data: {
            ...data,
            hospitalisation: !data.hospitalisation,
            aucun: false,
          },
        });
        break;
      case "incapacite":
        this.setState({
          data: { ...data, incapacite: !data.incapacite, aucun: false },
        });
        break;
      case "pronosticVital":
        this.setState({
          data: { ...data, pronosticVital: !data.pronosticVital, aucun: false },
        });
        break;
      case "deces":
        this.setState({ data: { ...data, deces: !data.deces, aucun: false } });
        break;
      case "situationGrave":
        this.setState({
          data: { ...data, situationGrave: !data.situationGrave, aucun: false },
        });
        break;
      case "anomalies":
        this.setState({
          data: { ...data, anomalies: !data.anomalies, aucun: false },
        });
        break;
      case "aucun":
        this.setState({
          data: {
            aucun: !data.aucun,
            hospitalisation: false,
            incapacite: false,
            pronosticVital: false,
            deces: false,
            situationGrave: false,
            anomalies: false,
          },
        });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">{"Gravité"}</p>
        <Grid className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </Grid>
        <p>L'effet indésirable a t'il entrainé (cocher la ou les cases) :</p>
        <Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox
              type="checkbox"
              id="hospitalisation"
              checked={data.hospitalisation}
            />
            <p style={{ cursor: "pointer" }} id="hospitalisation">
              une hospitalisation
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox
              type="checkbox"
              id="incapacite"
              checked={data.incapacite}
            />
            <p style={{ cursor: "pointer" }} id="incapacite">
              une incapacité fonctionnelle permanente ou temporaire
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox
              type="checkbox"
              id="pronosticVital"
              checked={data.pronosticVital}
            />
            <p style={{ cursor: "pointer" }} id="pronosticVital">
              mise en jeu du pronostic vital
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox type="checkbox" id="deces" checked={data.deces} />
            <p style={{ cursor: "pointer" }} id="deces">
              décès
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox
              type="checkbox"
              id="situationGrave"
              checked={data.situationGrave}
            />
            <p style={{ cursor: "pointer" }} id="situationGrave">
              des anomalies ou malformations congénitales
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox type="checkbox" id="anomalies" checked={data.anomalies} />
            <p style={{ cursor: "pointer" }} id="anomalies">
              autre situation medicale grave
            </p>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <Checkbox type="checkbox" id="aucun" checked={data.aucun} />
            <p style={{ cursor: "pointer" }} id="aucun">
              aucun de ces points
            </p>
          </Grid>
        </Grid>
        <Box className="bottomBtnForm">
          {data.hospitalisation ||
          data.incapacite ||
          data.pronosticVital ||
          data.deces ||
          data.situationGrave ||
          data.anomalies ||
          data.aucun ? (
            <Button
              variant="contained"
              color="blue"
              onClick={() => this.onSubmit()}
            >
              {"SUIVANT"}
            </Button>
          ) : (
            <Button variant="contained" disabled>
              {"SUIVANT"}
            </Button>
          )}
        </Box>
      </>
    );
  }
}
