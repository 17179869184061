import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { Trans } from "react-i18next";

import { searchDrugs } from "../../api/search";

export default function DrugSearchField({ onChange, selectedDrug }) {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const mutationSearchDrugs = useMutation({
    mutationFn: (data) => {
      return searchDrugs(data)
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        });
    },
    onSuccess: (results) => {
      let newOptions = [];

      if (selectedDrug) {
        newOptions = [selectedDrug];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setOptions(newOptions);
    },
  });

  const fetch = React.useMemo(
    () =>
      debounce((data) => {
        mutationSearchDrugs.mutate(data);
      }, 400),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    if (inputValue) {
      fetch({ search: inputValue });
    }
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="drug-search-field"
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.tradeName;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedDrug}
      loading={mutationSearchDrugs.isLoading}
      noOptionsText={<Trans>No matching drugs found</Trans>}
      freeSolo={true} // allow user to type anything
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (onChange) {
          onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);

        // To set input value as selected value in freeSolo mode
        if (onChange) {
          onChange(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<Trans>Nom du produit *</Trans>}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {mutationSearchDrugs.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(properties, option) => {
        return (
          <li {...properties} key={option.drugId}>
            <Grid container alignItems="center">
              <Grid item sx={{ width: "100%", wordWrap: "break-word" }}>
                <span>{option.tradeName}</span>
                <Typography variant="body2" color="text.secondary">
                  {option.activeSubstances?.join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
