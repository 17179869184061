import HomeIcon from "@mui/icons-material/Home";
import { Grid, ThemeProvider } from "@mui/material";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { Theme } from "../../styles/styles";

export const TermsAndConditions = () => {
  const [content, setContent] = useState("");
  const file =
    i18n.language === "fr"
      ? "TermsAndConditions.fr.md"
      : "TermsAndConditions.en.md";

  useEffect(() => {
    fetch(`files/${file}`)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      })
      .catch((err) => console.error("Error fetching the Markdown file:", err));
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <header className="headerApp">
        <Grid className="headerImputabilite">
          <div></div>

          <p className="headerText">CGU</p>
          <Link to="/">
            <HomeIcon color="white" />
          </Link>
        </Grid>
      </header>
      <Grid margin={"3em"}>
        <ReactMarkdown children={content} />
      </Grid>
    </ThemeProvider>
  );
};
