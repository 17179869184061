import { Button, Box, Grid } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import { config } from "../../config";
import { VigicareLogoWhite } from "../../styles/Icons";

export default class InfosProduits extends React.Component {
  onClickBack() {
    this.props.handler();
  }

  render() {
    return (
      <Grid>
        <header className="headerApp">
          <Grid className="headerImputabilite">
            <div className="headerDeclarationLeft">
              <svg
                className="returnArrow"
                onClick={() => this.onClickBack()}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <VigicareLogoWhite />
            </div>

            <p className="headerText">
              <Trans>Définir le type de produit</Trans>
            </p>
            <div></div>
          </Grid>
        </header>
        <div className="mainInfos listInfos">
          <Box className="infoProduit">
            <p>
              <Trans>
                <b>Médicament</b> :
              </Trans>
            </p>
            <ul>
              <li>
                <Trans>
                  Toute <b>substance</b> ou <b>composition</b> ayant pour but de{" "}
                  <b>traiter</b> ou de <b>prévenir les maladies</b> humaines ou
                  animales.
                </Trans>
              </li>
              <li>
                <Trans>
                  Toute <b>substance</b> ou <b>composition</b> vendue
                  exclusivement en <b>pharmacie</b>.
                </Trans>
              </li>
              <li>
                <Trans>Disponible avec ou sans ordonnance.</Trans>
              </li>
              <li>
                <Trans>
                  Administrée à une dose donnée sur une période déterminée.
                </Trans>
              </li>
            </ul>
          </Box>
          <Box className="infoProduit p1">
            <p>
              <Trans>
                <b>Dispositif médical</b> :
              </Trans>
            </p>
            <Grid className="infoListImg">
              <ul>
                <li>
                  <Trans>
                    Les produits de santé destinés à être utilisés à des fins de{" "}
                    <b>diagnostic</b>, de <b>prévention</b>, de <b>contrôle</b>,
                    de <b>traitement</b> ou <b>d'atténuation</b> d'une{" "}
                    <b>maladie</b> ou d'une <b>blessure</b>.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Tels que : les appareils auditifs, fauteuils roulants,
                    lunettes, pansements, prothèses de hanches, stimulateurs
                    cardiaques, implants dentaires, semelles orthopédiques,
                    autotests, réactifs de dosage, etc...
                  </Trans>
                </li>
              </ul>
              <img src={config.publicUrl + "/image2.png"} alt="CE symbol" />
            </Grid>
          </Box>
          <Box className="infoProduit">
            <p>
              <Trans>
                <b>Produit cosmétique</b> :
              </Trans>
            </p>
            <ul>
              <li>
                <Trans>
                  Les <b>produits d'hygiène</b> (ex: le gel douche, le déodorant
                  ou encore la mousse à raser).
                </Trans>
              </li>
              <li>
                <Trans>
                  Les <b>produits de soin</b> (ex: les crèmes de soin ou encore
                  les produits de dépigmentation).
                </Trans>
              </li>
              <li>
                <Trans>
                  Les <b>produits solaires</b> (ex: les protections solaires,
                  les autobronzants encore les après-soleil).
                </Trans>
              </li>
              <li>
                <Trans>
                  Les <b>produits de maquillage</b> (ex: le rouge à lèvres, le
                  fond de teint, le vernis à ongles, etc).
                </Trans>
              </li>
              <li>
                <Trans>
                  La <b>parfumerie</b> (ex: les parfums et eaux de parfums,
                  brume, etc).
                </Trans>
              </li>
              <li>
                <Trans>
                  Les <b>produits capillaires</b> (ex: les produits coiffants,
                  shampooings, lissages, colorations, etc).
                </Trans>
              </li>
            </ul>
          </Box>
          <Box className="infoProduit p1">
            <p>
              <Trans>
                <b>Compléments alimentaires</b> :
              </Trans>
            </p>
            <Grid className="infoListImg">
              <ul>
                <li>
                  <Trans>
                    Les produits destinés à être pris par <b>voie orale</b>{" "}
                    pouvant être retrouvés sous forme de gélules, capsules,
                    comprimés, poudre ou liquide.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Les produits visants à <b>combler d'éventuelles carences</b>{" "}
                    dans le cadre d'un mode de vie sain et d'une alimentation
                    équilibrée.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Contenant des <b>substances</b> appartenant à une des
                    catégories présentées sur le schéma ci-joint.
                  </Trans>
                </li>
              </ul>
              <img
                src={config.publicUrl + "/image3.png"}
                alt="tableau nutrionnel"
              />
            </Grid>
          </Box>
          <Grid textAlign={"end"} paddingTop="12px" paddingBottom="24px">
            <Button
              className="nextBottomButton"
              variant="contained"
              color="blue"
              onClick={() => this.onClickBack()}
            >
              <Trans>FERMER</Trans>
            </Button>
          </Grid>
        </div>
      </Grid>
    );
  }
}
