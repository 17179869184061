import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { validateAccount } from "../../api/user";
import { VigicareLogoWhiteXL, VigicareLogoBlack } from "../../styles/Icons";
import { Theme } from "../../styles/styles";

class Validation extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      validation: null,
    };

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (
      this.props.getValidateAccount.isError &&
      this.state.validation !== false
    ) {
      this.setState({ validation: false });
    } else if (
      this.props.getValidateAccount.isSuccess &&
      this.state.validation !== true
    ) {
      this.setState({ validation: true });
    }
  }

  render() {
    const { validation } = this.state;
    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <header className="headerSignIn">
            <div></div>
            <Grid>
              <p>
                <Trans>Bienvenue sur Vigicare</Trans>
              </p>
            </Grid>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </header>
          <div className="mainSignIn">
            <div className="accueilDisplayLogo">
              <VigicareLogoWhiteXL />
              <VigicareLogoBlack />
            </div>
            {validation === null ? (
              <Grid sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {validation ? (
                  <Grid>
                    <p className="authTitle">
                      <Trans>Inscription validée !</Trans>
                    </p>
                    <p className="">
                      <Trans>
                        Vous pouvez desormais vous connecter avec vos
                        identifiants
                      </Trans>
                    </p>
                    <Box className="bottomBtnQuestionnaire bottomBtnCo">
                      <Link className="linkButton" to={"/auth"}>
                        <Button
                          fullWidth
                          className="fullBtn"
                          variant="contained"
                          color="blue"
                        >
                          <Trans>Connectez-vous</Trans>
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                ) : (
                  <Grid>
                    <p className="authTitle">
                      <Trans>Erreur dans la validation de l'inscription</Trans>
                    </p>
                    <Box className="bottomBtnQuestionnaire bottomBtnCo">
                      <Link className="linkButton" to={"/"}>
                        <Button
                          fullWidth
                          className="fullBtn"
                          variant="outlined"
                          color="blue"
                        >
                          <Trans>Retour à l'accueil</Trans>
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation()(Validation)));

function withRouter(Child) {
  return (properties) => {
    const params = useParams();
    const navig = useNavigate();
    return <Child {...properties} params={params} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const token = properties.params.token;
    const getValidateAccount = useQuery(["validate-token"], () =>
      validateAccount(token)
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );

    return <Child {...properties} getValidateAccount={getValidateAccount} />;
  };
}
