import { Visibility, VisibilityOff } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { validateToken, updateRecoverPassword } from "../../api/user";
import { VigicareLogoWhiteXL, VigicareLogoBlack } from "../../styles/Icons";
import { Theme } from "../../styles/styles";

import "./auth.css";

class MotdepasseNouveau extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      password: "",
      passwordVerif: "",
      showPassword: false,
      showPasswordVerif: false,
      tokenValid: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleClickShowPasswordVerif =
      this.handleClickShowPasswordVerif.bind(this);

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (
      this.props.getValidateToken.isError &&
      this.state.tokenValid !== false
    ) {
      this.setState({ tokenValid: false });
    } else if (
      this.props.getValidateToken.isSuccess &&
      this.state.tokenValid !== true
    ) {
      this.setState({ tokenValid: true });
    }
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickShowPasswordVerif() {
    this.setState({ showPasswordVerif: !this.state.showPasswordVerif });
  }

  handleChange(event_) {
    switch (event_.target.id) {
      case "passwordVerif":
        this.setState({ passwordVerif: event_.target.value });
        break;
      case "password":
        this.setState({ password: event_.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      password,
      passwordVerif,
      showPasswordVerif,
      showPassword,
      tokenValid,
    } = this.state;
    const { mutationUpdatePassword } = this.props;
    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <header className="headerSignIn">
            <div></div>
            <Grid>
              <p>
                <Trans>Vigicare</Trans>
              </p>
            </Grid>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </header>
          <div className="mainSignIn">
            {mutationUpdatePassword.isSuccess ? (
              <Grid>
                <p className="authTitle">
                  <Trans i18nKey="auth:auth.forgotPassword.resetPassword">
                    Créez votre nouveau mot de passe
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="auth:auth.forgotPassword.message.success">
                    Mot de passe modifié avec succès
                  </Trans>
                </p>
                <Box className="bottomBtnQuestionnaire bottomBtnAuth">
                  <Link to="/auth">
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "unset !important",
                        marginBottom: "16px",
                        padding: "10px 0px 10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <Trans>Connectez-vous</Trans>
                    </Button>
                  </Link>
                </Box>
              </Grid>
            ) : (
              <>
                {tokenValid === null ? (
                  <>
                    <Grid>
                      <Grid sx={{ textAlign: "center", paddingTop: "1em" }}>
                        <CircularProgress />
                      </Grid>

                      <Box className="bottomBtnQuestionnaire bottomBtnAuth">
                        <Link to="/">
                          <Button variant="contained">
                            <Trans>Retour à l'accueil</Trans>
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </>
                ) : tokenValid ? (
                  <>
                    <p className="authTitle">
                      <Trans i18nKey="auth:auth.forgotPassword.resetPassword">
                        Créez votre nouveau mot de passe
                      </Trans>
                    </p>

                    <TextField
                      fullWidth
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={this.handleChange}
                      label={<Trans>Votre nouveau mot de passe</Trans>}
                      sx={{ marginTop: "12px" }}
                      error={
                        password !== "" &&
                        password.match(
                          /^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/
                        ) === null
                      }
                      helperText={
                        <Trans>
                          Votre mot de passe doit contenir au moins 8
                          caractères, dont au moins 1 majuscule, 1 chiffre et 1
                          symbole (.!@#$&*)
                        </Trans>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color={"#DDDDDD"}
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      id="passwordVerif"
                      type={showPasswordVerif ? "text" : "password"}
                      value={passwordVerif}
                      onChange={this.handleChange}
                      error={passwordVerif !== "" && password !== passwordVerif}
                      label={
                        <Trans>Confirmer votre nouveau mot de passe</Trans>
                      }
                      sx={{ marginTop: "12px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color={"#DDDDDD"}
                              onClick={this.handleClickShowPasswordVerif}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {mutationUpdatePassword.isError && (
                      <p className="errorLabel">
                        <Trans i18nKey="auth:auth.forgotPassword.message.error.title">
                          Erreur dans la modification du mot de passe
                        </Trans>
                      </p>
                    )}
                    <Box className="bottomBtnQuestionnaire bottomBtnAuth">
                      <Button
                        disabled={
                          passwordVerif === "" ||
                          password === "" ||
                          passwordVerif !== password ||
                          password.match(
                            /^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/
                          ) === null
                        }
                        onClick={() =>
                          mutationUpdatePassword.mutate({ password })
                        }
                        variant="contained"
                        color="blue"
                        sx={{
                          textTransform: "unset !important",
                          marginBottom: "16px",
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        <Trans i18nKey="auth:auth.action.reset">Reset</Trans>
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid>
                      <div className="accueilDisplayLogo">
                        <VigicareLogoWhiteXL />
                        <VigicareLogoBlack />
                      </div>
                      <p className="authTitle">
                        <Trans i18nKey="auth:auth.forgotPassword.resetPassword">
                          Créez votre nouveau mot de passe
                        </Trans>
                      </p>
                      <p>
                        <Trans i18nKey="auth:auth.forgotPassword.message.invalid">
                          Identification invalide
                        </Trans>
                      </p>
                      <Box className="bottomBtnQuestionnaire bottomBtnAuth">
                        <Link to="/">
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "unset !important",
                            }}
                          >
                            <Trans>Retour à l'accueil</Trans>
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            )}
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation()(MotdepasseNouveau)));

function withRouter(Child) {
  return (properties) => {
    const params = useParams();
    const navig = useNavigate();
    return <Child {...properties} params={params} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const token = properties.params.token;
    const getValidateToken = useQuery(["validate-token"], () =>
      validateToken(token)
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );

    const mutationUpdatePassword = useMutation({
      mutationFn: (data) => {
        return updateRecoverPassword(data, token)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    return (
      <Child
        {...properties}
        mutationUpdatePassword={mutationUpdatePassword}
        getValidateToken={getValidateToken}
      />
    );
  };
}
