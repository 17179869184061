import { Grid, Button, TextField, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";

import { dptsFr } from "../../../misc/dataset";

export default class Effets extends React.Component {
  constructor(properties) {
    super(properties);

    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          dept: properties.data.dept !== undefined ? properties.data.dept : "",
          date:
            properties.data.date !== undefined ? properties.data.date : null,
          duree:
            properties.data.duree !== undefined ? properties.data.duree : "",
          description:
            properties.data.description !== undefined
              ? properties.data.description
              : "",
          uniteDuree:
            properties.data.uniteDuree !== undefined
              ? properties.data.uniteDuree
              : "Jours",
        },
      };
    } else {
      this.state = {
        data: {
          dept: "",
          date: null,
          duree: "",
          description: "",
          uniteDuree: "Jours",
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleChangeDropdownDept = this.handleChangeDropdownDept.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDropdown(event_) {
    if (event_.target.value !== undefined) {
      this.setState({
        data: { ...this.state.data, uniteDuree: event_.target.value },
      });
    }
  }

  handleChangeDropdownDept(event_) {
    if (event_.target.value !== undefined) {
      this.setState({
        data: { ...this.state.data, dept: event_.target.value },
      });
    }
  }
  handleChangeDate(event_) {
    this.setState({ data: { ...this.state.data, date: event_ } });
  }

  handleChange(event_) {
    switch (event_.target.id) {
      case "dept":
        this.setState({
          data: { ...this.state.data, dept: event_.target.value },
        });
        break;
      case "duree":
        this.setState({
          data: { ...this.state.data, duree: event_.target.value },
        });
        break;
      case "description":
        this.setState({
          data: { ...this.state.data, description: event_.target.value },
        });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    const { data } = this.state;
    if (data.date !== null && data.date.isValid() && data.date.isBefore())
      this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">{"Effet(s)"}</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <div className="inputForm">
          <TextField
            select
            id="dept"
            value={data.dept}
            onChange={this.handleChangeDropdownDept}
            sx={{ minWidth: "35%" }}
            label="Departement de survenue *"
          >
            {dptsFr.map((dataDpt) => (
              <MenuItem key={dataDpt.code} value={dataDpt.code}>
                {"(" + dataDpt.code + ") " + dataDpt.d}
              </MenuItem>
            ))}
          </TextField>

          <DesktopDatePicker
            id="date"
            inputFormat="DD/MM/YYYY"
            value={data.date !== null ? data.date : ""}
            onChange={this.handleChangeDate}
            label="Date de survenue *"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={
                    data.date !== null &&
                    (!data.date.isValid() || !data.date.isBefore())
                  }
                />
              );
            }}
          />
          <Grid className="formRowDouble">
            <TextField
              fullWidth
              id="duree"
              type="number"
              value={data.duree}
              onChange={this.handleChange}
              label="Durée de l'effet *"
            />
            <TextField
              select
              value={data.uniteDuree}
              onChange={this.handleChangeDropdown}
              sx={{ minWidth: "35%", textAlign: "end" }}
            >
              <MenuItem value="Jours">Jours</MenuItem>
              <MenuItem value="Semaines">Semaines</MenuItem>
              <MenuItem value="Années">Années</MenuItem>
            </TextField>
          </Grid>

          <TextField
            id="description"
            multiline
            rows={6}
            type="text"
            value={data.description}
            onChange={this.handleChange}
            label="Description de l'effet indésirable *"
          />
          <p className="requiredMessage">
            <Trans>* Champs obligatoires</Trans>
          </p>
          <p className="robotoFont">
            <b>
              Bien préciser la chronologie et l'évolution des troubles cliniques
              et biologiques avec les dates, par exemple :
            </b>
            <br />
            <br />
            - après la survenue de l'effet indésirable, si un (ou plusieurs)
            médicament(s) ont été arrêtés (préciser lesquels)
            <br />
            - s'il y a eu disparition de l'effet après arrêt du (ou des)
            médicament(s) (préciser lesquels)
            <br />- si un ou plusieurs médicaments ont été réintroduit(s)
            (préciser lesquels) avec l'évolution de l'effet indésirable après
            réintroduction.
          </p>
          {data.dept !== "" &&
          data.date !== null &&
          data.date.isValid() &&
          data.date.isBefore() &&
          data.duree !== "" &&
          data.description !== "" ? (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.onSubmit()}
              >
                {"SUIVANT"}
              </Button>
            </Grid>
          ) : (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button variant="contained" disabled>
                {"SUIVANT"}
              </Button>
            </Grid>
          )}
        </div>
      </>
    );
  }
}
