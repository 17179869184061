import { Grid, Button, Box, TextField } from "@mui/material";
import React from "react";

export default class Antecedents extends React.Component {
  constructor(properties) {
    super(properties);

    if (properties.data !== undefined && properties.data !== "") {
      this.state = {
        text: properties.data,
      };
    } else {
      this.state = {
        text: "",
      };
    }

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    if (event_.target.value !== undefined)
      this.setState({ text: event_.target.value });
  }

  onSubmit() {
    this.props.handler(this.state.text);
  }

  render() {
    const { text } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <Grid>
        <p className="pageTitle">Antécédents du patient</p>
        <Grid className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </Grid>
        <p>
          Antécédents du patient / Facteurs ayant pu favoriser la survenue de
          l'effet indésirable :
        </p>
        <div className="inputForm">
          <TextField
            multiline
            rows={6}
            value={text}
            onChange={this.handleChange}
          />
        </div>
        <Box className="bottomBtnForm">
          <Button
            variant="contained"
            color="blue"
            onClick={() => this.onSubmit()}
          >
            {text === "" ? "PASSER" : "CONFIRMER"}
          </Button>
        </Box>
      </Grid>
    );
  }
}
