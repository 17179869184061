import AddIcon from "@mui/icons-material/Add";
import { Grid, Button, Box, TextField, Radio, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";

import { CrossIcon } from "../../../styles/Icons";
import "reactjs-popup/dist/index.css";

export default class ProduitAlim extends React.Component {
  constructor(properties) {
    super(properties);
    if (properties.data !== undefined && properties.data.length !== 0) {
      this.state = {
        produits: [...properties.data],
        showInfo: false,
        errorIncomplete: false,
      };
    } else {
      this.state = {
        produits: [],
        showInfo: false,
        errorIncomplete: false,
      };
    }

    this.createProd = this.createProd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
    this.handleChangeDropdownDuree = this.handleChangeDropdownDuree.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDateStart(event_, index) {
    let prods = [...this.state.produits];
    let newProduction = { ...prods[index] };
    newProduction.dateDebutConso = event_;
    prods[index] = newProduction;

    this.setState({ produits: prods });
  }

  handleChangeDateEnd(event_, index) {
    let prods = [...this.state.produits];
    let newProduction = { ...prods[index] };
    newProduction.dateFinConso = event_;
    prods[index] = newProduction;

    this.setState({ produits: prods });
  }

  handleChangeDropdownDuree(event_, index) {
    let prods = [...this.state.produits];
    let newProduction = { ...prods[index] };
    newProduction.uniteDuree = event_.target.value;
    prods[index] = newProduction;

    this.setState({ produits: prods });
  }

  handleChange(event_, index) {
    let prods = [...this.state.produits];
    let newProduction = { ...prods[index] };
    switch (event_.target.id) {
      case "nomCom": {
        newProduction.nomCom = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "fabricant": {
        newProduction.fabricant = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "numLot": {
        newProduction.numLot = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "motifConso": {
        newProduction.motifConso = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "doseConso": {
        newProduction.doseConso = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "lieuText": {
        newProduction.lieuAchatAutre = event_.target.value;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "lieuPharm": {
        newProduction.lieuAchat = "pharmacie";
        newProduction.lieuAchatAutre = "";
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "lieuInt": {
        newProduction.lieuAchat = "internet";
        newProduction.lieuAchatAutre = "";
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "lieuAutre": {
        newProduction.lieuAchat = "autre";
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "lieuNSP": {
        newProduction.lieuAchat = "nsp";
        newProduction.lieuAchatAutre = "";
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "arretTrue": {
        newProduction.arretProduit = true;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "arretFalse": {
        newProduction.arretProduit = false;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "arretNSP": {
        newProduction.arretProduit = null;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "dspTrue": {
        newProduction.disparitionEffets = true;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "dspFalse": {
        newProduction.disparitionEffets = false;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "dspNull": {
        newProduction.disparitionEffets = null;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "repriseTrue": {
        newProduction.repriseConso = true;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "repriseFalse": {
        newProduction.repriseConso = false;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "repriseNull": {
        newProduction.repriseConso = null;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "retourTrue": {
        newProduction.retourEffet = true;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "retourFalse": {
        newProduction.retourEffet = false;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      case "retourNull": {
        newProduction.retourEffet = null;
        prods[index] = newProduction;
        this.setState({ produits: prods });
        break;
      }
      default:
        break;
    }
  }

  createProd() {
    let newProduction = {
      nomCom: "",
      fabricant: "",
      numLot: "",
      motifConso: "",
      doseConso: "",
      uniteDuree: "par jour",
      lieuAchat: "",
      lieuAchatAutre: "",
      dateDebutConso: null,
      dateFinConso: null,
      arretProduit: null,
      disparitionEffets: null,
      repriseConso: null,
      retourEffet: null,
    };
    this.setState({ produits: [...this.state.produits, newProduction] });
  }

  removeProd(id) {
    this.setState({
      produits: this.state.produits.filter(function (production, index) {
        return index !== id;
      }),
    });
  }

  onSubmit() {
    const { produits } = this.state;
    if (produits[0] !== undefined) {
      const production = produits[0];

      if (
        production !== undefined &&
        production.name !== "" &&
        production.posology !== "" &&
        production.numLot !== "" &&
        production.administrationRoute !== "" &&
        production.dateDebutConso !== null &&
        production.dateDebutConso.isBefore() &&
        production.dateDebutConso.isValid()
      ) {
        if (production.dateFinConso !== null) {
          if (
            production.dateFinConso.isBefore() &&
            production.dateFinConso.isValid() &&
            production.dateDebutConso.isSameOrBefore(production.dateFinConso)
          )
            this.props.handler(this.state.produits);
        } else this.props.handler(this.state.produits);
      }
    }
  }

  render() {
    const { produits } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";

    return (
      <Grid>
        <p className="pageTitle">{"Produits alimentaire(s) suspecté(s)"}</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <p>
          {" "}
          Ajouter le ou les médicaments à l'origine de l'effet indésirable :{" "}
        </p>
        <div>
          {produits.map((production, i) => {
            return (
              <Grid key={i}>
                {i > 0 ? <hr className="separator" /> : null}
                <div className="medTitle">
                  <p className="medTitleP">PRODUIT {i + 1}</p>
                  <p onClick={() => this.removeProd(i)}>
                    <CrossIcon />
                  </p>
                </div>

                <form className="medForm">
                  <TextField
                    id="nomCom"
                    type="text"
                    value={production.nomCom}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Nom commercial *"
                  />
                  <TextField
                    id="fabricant"
                    type="text"
                    value={production.fabricant}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Fabricant *"
                  />
                  <TextField
                    id="numLot"
                    type="text"
                    value={production.numLot}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Numéro de lot *"
                  />
                  <TextField
                    id="motifConso"
                    type="text"
                    value={production.motifConso}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Motif de la consommation"
                  />

                  <Grid className="formRowDouble">
                    <TextField
                      fullWidth
                      id="doseConso"
                      type="number"
                      value={production.doseConso}
                      onChange={(e) => this.handleChange(e, i)}
                      label="Dose de la consommation *"
                    />
                    <TextField
                      select
                      value={production.uniteDuree}
                      onChange={(e) => this.handleChangeDropdownDuree(e, i)}
                      sx={{ minWidth: "45%", textAlign: "end" }}
                    >
                      <MenuItem value="par jour">par jour</MenuItem>
                      <MenuItem value="par semaine">par semaine</MenuItem>
                      <MenuItem value="par mois">par mois</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid>
                    <p className="robotoFontB">Lieu d'achat *</p>
                    <Grid className="inputRadioRowGrid">
                      <label className="robotoFont">
                        <Radio
                          id="lieuPharm"
                          checked={production.lieuAchat === "pharmacie"}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Pharmacie"}
                      </label>
                      <label className="robotoFont">
                        <Radio
                          id="lieuInt"
                          checked={production.lieuAchat === "internet"}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Internet"}
                      </label>
                    </Grid>
                    <Grid className="inputRadioRowGrid">
                      <label className="robotoFont">
                        <Radio
                          id="lieuAutre"
                          checked={production.lieuAchat === "autre"}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Autre"}
                      </label>
                      <label className="robotoFont">
                        <Radio
                          id="lieuNSP"
                          checked={production.lieuAchat === "nsp"}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Je ne sais pas"}
                      </label>
                    </Grid>
                    {production.lieuAchat === "autre" ? (
                      <TextField
                        fullWidth
                        id="lieuText"
                        type="text"
                        value={production.lieuAchatAutre}
                        onChange={(e) => this.handleChange(e, i)}
                        label="Si autre, précisez"
                      />
                    ) : null}
                  </Grid>

                  <DesktopDatePicker
                    id="dateDebutConso"
                    inputFormat="DD/MM/YYYY"
                    value={production.dateDebutConso}
                    onChange={(e) => this.handleChangeDateStart(e, i)}
                    label="Date de début de consommation *"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={
                            production.dateDebutConso !== null &&
                            (!production.dateDebutConso.isBefore() ||
                              !production.dateDebutConso.isValid())
                          }
                        />
                      );
                    }}
                  />
                  <Grid>
                    <p className="robotoFontB">Arrêt du produit</p>
                    <Grid className="inputRadioRow">
                      <label className="robotoFont">
                        <Radio
                          id="arretTrue"
                          checked={production.arretProduit === true}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Oui"}
                      </label>
                      <label className="robotoFont">
                        <Radio
                          id="arretFalse"
                          checked={production.arretProduit === false}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Non"}
                      </label>
                      <label className="robotoFont">
                        <Radio
                          id="arretNSP"
                          checked={production.arretProduit === null}
                          onChange={(e) => this.handleChange(e, i)}
                        />
                        {"  Je ne sais pas"}
                      </label>
                    </Grid>
                  </Grid>
                  {production.arretProduit === true ? (
                    <DesktopDatePicker
                      id="dateFinConso"
                      inputFormat="DD/MM/YYYY"
                      disabled={production.arretProduit !== true}
                      value={production.dateFinConso}
                      onChange={(e) => this.handleChangeDateEnd(e, i)}
                      label="Date de fin de la consommation"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            error={
                              production.dateFinConso !== null &&
                              production.arretProduit === true &&
                              (!production.dateFinConso.isBefore() ||
                                !production.dateFinConso.isValid() ||
                                !(
                                  production.dateDebutConso !== null &&
                                  production.dateFinConso.isSameOrAfter(
                                    production.dateDebutConso
                                  )
                                ))
                            }
                          />
                        );
                      }}
                    />
                  ) : null}
                  {production.arretProduit === true ? (
                    <>
                      <Grid>
                        <p className="robotoFontB">
                          Disparition/regression des effets à l'arret
                        </p>
                        <Grid className="inputRadioRow">
                          <label className="robotoFont">
                            <Radio
                              id="dspTrue"
                              checked={production.disparitionEffets === true}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Oui"}
                          </label>
                          <label className="robotoFont">
                            <Radio
                              id="dspFalse"
                              checked={production.disparitionEffets === false}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Non"}
                          </label>
                          <label className="robotoFont">
                            <Radio
                              id="dspNull"
                              checked={production.disparitionEffets === null}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Je ne sais pas"}
                          </label>
                        </Grid>
                      </Grid>

                      <Grid>
                        <p className="robotoFontB">
                          Reprise de la consommation du produit
                        </p>
                        <Grid className="inputRadioRow">
                          <label className="robotoFont">
                            <Radio
                              id="repriseTrue"
                              checked={production.repriseConso === true}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Oui"}
                          </label>
                          <label className="robotoFont">
                            <Radio
                              id="repriseFalse"
                              checked={production.repriseConso === false}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Non"}
                          </label>
                          <label className="robotoFont">
                            <Radio
                              id="repriseNull"
                              checked={production.repriseConso === null}
                              onChange={(e) => this.handleChange(e, i)}
                            />
                            {"  Je ne sais pas"}
                          </label>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {production.repriseConso === true ? (
                    <Grid>
                      <p className="robotoFontB">
                        Si oui, l'effet indesirable est-il réapparu ?
                      </p>
                      <Grid className="inputRadioRow">
                        <label className="robotoFont">
                          <Radio
                            id="retourTrue"
                            checked={production.retourEffet === true}
                            onChange={(e) => this.handleChange(e, i)}
                          />
                          {"  Oui"}
                        </label>
                        <label className="robotoFont">
                          <Radio
                            id="retourFalse"
                            checked={production.retourEffet === false}
                            onChange={(e) => this.handleChange(e, i)}
                          />
                          {"  Non"}
                        </label>
                        <label className="robotoFont">
                          <Radio
                            id="retourNull"
                            checked={production.retourEffet === null}
                            onChange={(e) => this.handleChange(e, i)}
                          />
                          {"  Je ne sais pas"}
                        </label>
                      </Grid>
                    </Grid>
                  ) : null}
                </form>
              </Grid>
            );
          })}
          {produits.length > 0 ? (
            <p className="requiredMessage">
              <Trans>* Champs obligatoires</Trans>
            </p>
          ) : null}
          {produits.length !== undefined && produits.length < 3 ? (
            <Box className="boxBtn" marginTop="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.createProd()}
              >
                <AddIcon fontSize="small" sx={{ paddingRight: "12px" }} />
                {"AJOUTER UN PRODUIT"}
              </Button>
            </Box>
          ) : null}

          {produits[0] !== undefined &&
          produits[0].nomCom !== "" &&
          produits[0].numLot !== "" &&
          produits[0].dateDebutConso !== null &&
          produits[0].fabricant !== "" &&
          produits[0].doseConso !== "" &&
          produits[0].lieuAchat !== "" ? (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.onSubmit()}
              >
                {"SUIVANT"}
              </Button>
            </Grid>
          ) : (
            <Grid textAlign={"end"} marginBottom="24px">
              <Button variant="contained" disabled>
                {"SUIVANT"}
              </Button>
            </Grid>
          )}
        </div>
      </Grid>
    );
  }
}
