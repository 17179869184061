import { Button, Checkbox, Grid, Box, TextField } from "@mui/material";
import React from "react";

export default class Consequences extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          consultPharmacien: properties.data.consultPharmacien,
          consultMedecin: properties.data.consultMedecin,
          consultDentiste: properties.data.consultDentiste,
          geneSociale: properties.data.geneSociale,
          arretTravail: properties.data.arretTravail,
          intervMedicale: properties.data.intervMedicale,
          hospitalisation: properties.data.hospitalisation,
          sequelles: properties.data.sequelles,
          autre: properties.data.autre,
          geneTexte: properties.data.geneTexte,
          intervMedText: properties.data.intervMedText,
        },
      };
    } else {
      this.state = {
        data: {
          consultPharmacien: false,
          consultMedecin: false,
          consultDentiste: false,
          geneSociale: false,
          arretTravail: false,
          intervMedicale: false,
          hospitalisation: false,
          sequelles: false,
          autre: false,
          geneTexte: "",
          intervMedText: "",
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "consultPharmacien":
        this.setState({
          data: { ...data, consultPharmacien: !data.consultPharmacien },
        });
        break;
      case "consultMedecin":
        this.setState({
          data: { ...data, consultMedecin: !data.consultMedecin },
        });
        break;
      case "consultDentiste":
        this.setState({
          data: { ...data, consultDentiste: !data.consultDentiste },
        });
        break;
      case "geneSociale":
        this.setState({
          data: { ...data, geneSociale: !data.geneSociale, geneTexte: "" },
        });
        break;
      case "arretTravail":
        this.setState({ data: { ...data, arretTravail: !data.arretTravail } });
        break;
      case "intervMedicale":
        this.setState({
          data: {
            ...data,
            intervMedicale: !data.intervMedicale,
            intervMedText: "",
          },
        });
        break;
      case "hospitalisation":
        this.setState({
          data: { ...data, hospitalisation: !data.hospitalisation },
        });
        break;
      case "sequelles":
        this.setState({ data: { ...data, sequelles: !data.sequelles } });
        break;
      case "autre":
        this.setState({ data: { ...data, autre: !data.autre } });
        break;
      case "geneTexte":
        this.setState({ data: { ...data, geneTexte: event_.target.value } });
        break;
      case "intervMedText":
        this.setState({
          data: { ...data, intervMedText: event_.target.value },
        });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">Conséquences</p>
        <Grid className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </Grid>
        <Grid>
          <p className="robotoFontB">Conséquences de l'effet indésirable :</p>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox
                id="consultPharmacien"
                checked={data.consultPharmacien}
              />
              {" Consultation pharmacien"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="consultMedecin" checked={data.consultMedecin} />
              {" Consultation médecin"}
            </label>
          </Grid>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="consultDentiste" checked={data.consultDentiste} />
              {" Consultation dentiste"}
            </label>
          </Grid>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="geneSociale" checked={data.geneSociale} />
              {" Gêne sociale"}
            </label>
          </Grid>
          {data.geneSociale === true ? (
            <TextField
              fullWidth
              id="geneTexte"
              type="text"
              value={data.geneTexte}
              onChange={this.handleChange}
              label="Précisez"
            />
          ) : null}

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="arretTravail" checked={data.arretTravail} />
              {" Arret de travail"}
            </label>
          </Grid>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="intervMedicale" checked={data.intervMedicale} />
              {" Intervention medicale urgente"}
            </label>
          </Grid>
          {data.intervMedicale === true ? (
            <TextField
              fullWidth
              id="intervMedText"
              type="text"
              value={data.intervMedText}
              onChange={this.handleChange}
              label="Précisez"
            />
          ) : null}

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="hospitalisation" checked={data.hospitalisation} />
              {" Hospitalisation"}
            </label>
          </Grid>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="sequelles" checked={data.sequelles} />
              {" Séquelles, invalidité ou incapacité"}
            </label>
          </Grid>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="autre" checked={data.autre} />
              {" Autres"}
            </label>
          </Grid>

          <Box className="bottomBtnForm">
            <Button
              variant="contained"
              color="blue"
              onClick={() => this.onSubmit()}
            >
              {"SUIVANT"}
            </Button>
          </Box>
        </Grid>
      </>
    );
  }
}
