import { Box, Button } from "@mui/material";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { VigicareLogoWhiteXL, VigicareLogoBlack } from "../../styles/Icons";

class InscriptionValide extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {};

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="accueilDisplayLogo">
          <VigicareLogoWhiteXL />
          <VigicareLogoBlack />
        </div>
        <p className="authTitle">
          <Trans>Inscription réussie !</Trans>
        </p>
        <p>
          <Trans>Vous avez reçu un mail pour valider votre inscription</Trans>
        </p>
        <Link to="/auth" className="linkButton"></Link>
        <Box className="bottomBtnQuestionnaire bottomBtnCo">
          <Link className="linkButton" to={"/auth"}>
            <Button
              fullWidth
              className="fullBtn"
              variant="contained"
              color="blue"
            >
              <Trans>Connectez-vous</Trans>
            </Button>
          </Link>
        </Box>
      </>
    );
  }
}

export default withTranslation()(InscriptionValide);
