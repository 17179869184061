import { Button, TextField, Box } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

export default class ProduitCosmetique extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          numLot:
            properties.data.numLot !== undefined ? properties.data.numLot : "",
          nomComplet:
            properties.data.nomComplet !== undefined
              ? properties.data.nomComplet
              : "",
          societe:
            properties.data.societe !== undefined
              ? properties.data.societe
              : "",
          fonctionProd:
            properties.data.fonctionProd !== undefined
              ? properties.data.fonctionProd
              : "",
          lieuAchat:
            properties.data.lieuAchat !== undefined
              ? properties.data.lieuAchat
              : "",
        },
        errorIncomplete: false,
      };
    } else {
      this.state = {
        data: {
          numLot: "",
          nomComplet: "",
          societe: "",
          fonctionProd: "",
          lieuAchat: "",
        },
        errorIncomplete: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "numLot":
        this.setState({
          data: { ...data, numLot: event_.target.value },
          errorIncomplete: false,
        });
        break;
      case "nomComplet":
        this.setState({
          data: { ...data, nomComplet: event_.target.value },
          errorIncomplete: false,
        });
        break;
      case "societe":
        this.setState({
          data: { ...data, societe: event_.target.value },
          errorIncomplete: false,
        });
        break;
      case "fonctionProd":
        this.setState({
          data: { ...data, fonctionProd: event_.target.value },
          errorIncomplete: false,
        });
        break;
      case "lieuAchat":
        this.setState({
          data: { ...data, lieuAchat: event_.target.value },
          errorIncomplete: false,
        });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    const { data } = this.state;
    if (
      data.numLot !== "" &&
      data.nomComplet !== "" &&
      data.societe !== "" &&
      data.fonctionProd !== "" &&
      data.lieuAchat !== ""
    )
      this.props.handler(this.state.data);
    else {
      this.setState({ errorIncomplete: true });
    }
  }

  render() {
    const { data, errorIncomplete } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">Produit</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        {errorIncomplete ? <p className="errorText">Champs manquants</p> : null}

        <div className="inputForm">
          <TextField
            fullWidth
            id="numLot"
            type="text"
            value={data.numLot}
            onChange={this.handleChange}
            label="Numéro de lot *"
          />
          <TextField
            fullWidth
            id="nomComplet"
            type="text"
            value={data.nomComplet}
            onChange={this.handleChange}
            label="Nom complet *"
          />
          <TextField
            fullWidth
            id="societe"
            type="text"
            value={data.societe}
            onChange={this.handleChange}
            label="Société / Marque *"
          />
          <TextField
            fullWidth
            id="fonctionProd"
            type="text"
            value={data.fonctionProd}
            onChange={this.handleChange}
            label="Usage / Fonction du produit *"
          />
          <TextField
            fullWidth
            id="lieuAchat"
            type="text"
            value={data.lieuAchat}
            onChange={this.handleChange}
            label="Lieu d'achat *"
          />
        </div>

        <p className="requiredMessage">
          <Trans>* Champs obligatoires</Trans>
        </p>

        {data.numLot !== "" &&
        data.nomComplet !== "" &&
        data.societe !== "" &&
        data.fonctionProd !== "" &&
        data.lieuAchat !== "" ? (
          <Box className="bottomBtnForm">
            <Button
              variant="contained"
              color="blue"
              onClick={() => this.onSubmit()}
            >
              {"SUIVANT"}
            </Button>
          </Box>
        ) : (
          <Box className="bottomBtnForm">
            <Button
              variant="contained"
              disabled
              onClick={() => this.onSubmit()}
            >
              {"SUIVANT"}
            </Button>
          </Box>
        )}
      </>
    );
  }
}
