import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  Autocomplete,
  Box,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import i18n from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

import { effectsListFR, effectsListEN } from "../../../../misc/dataset";
import { CrossIcon } from "../../../../styles/Icons";

export const Usage = ({ data, handleChange, handlePrev, handleNext }) => {
  useTranslation();

  const handleChangeDropdownSex = (event_) => {
    handleChange({ ...data, sex: event_.target.value });
  };

  const handleChangeDropdownAge = (event_) => {
    handleChange({ ...data, trancheAge: event_.target.value });
  };

  const handleChangeDatePrise = (event_) => {
    handleChange({ ...data, datePrise: event_ });
  };

  const handleChangeDateEffet = (event_) => {
    handleChange({ ...data, dateEffet: event_ });
  };

  const displayList = (lng) => {
    switch (lng) {
      case "fr":
        return effectsListFR.map((effect) => ({
          label: effect,
          value: effect,
        }));
      case "en":
        return effectsListEN.map((effect) => ({
          label: effect,
          value: effectsListFR[effectsListEN.indexOf(effect)],
        }));
      default:
        return effectsListFR.map((effect) => ({
          label: effect,
          value: effect,
        }));
    }
  };

  const handleChangeDropdownEffet = (selectedOption, i) => {
    let newEffets = [...data.typeEffet];

    newEffets[i] = selectedOption;
    handleChange({ ...data, typeEffet: newEffets });
  };

  const handleRemoveEffect = (i) => {
    let newEffets = [...data.typeEffet];
    newEffets.splice(i, 1);
    handleChange({ ...data, typeEffet: newEffets });
  };

  const handleAddEffect = () => {
    if (data.typeEffet.length < 5) {
      handleChange({ ...data, typeEffet: [...data.typeEffet, null] });
    }
  };

  const handleChangeInput = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "descEffet":
        handleChange({ ...data, descEffet: value });
        break;
      case "hospitalisation":
        handleChange({
          ...data,
          hospitalisation: !data.hospitalisation,
          aucun: false,
        });
        break;
      case "incapacite":
        handleChange({ ...data, incapacite: !data.incapacite, aucun: false });
        break;
      case "handicap":
        handleChange({ ...data, handicap: !data.handicap, aucun: false });
        break;
      case "anomalies":
        handleChange({ ...data, anomalies: !data.anomalies, aucun: false });
        break;
      case "risqueVital":
        handleChange({ ...data, risqueVital: !data.risqueVital, aucun: false });
        break;
      case "aucun":
        handleChange({
          ...data,
          aucun: !data.aucun,
          hospitalisation: false,
          incapacite: false,
          handicap: false,
          anomalies: false,
          risqueVital: false,
        });
        break;
      default:
        break;
    }
  };

  const renderMessage = () => {
    if (!data.proSante) {
      return null;
    }

    let message = null;

    if (data.typeProduit === "Médicament") {
      message = (
        <Trans>
          En tant que professionnel(le) de santé, vous avez l'obligation légale
          de déclarer l'effet indésirable d'un médicament.
        </Trans>
      );
    }

    if (data.typeProduit === "Complément alimentaire") {
      message = (
        <Trans>
          En tant que professionnel(le) de santé, vous avez l'obligation légale
          de déclarer l'effet indésirable d'un complément alimentaire.
        </Trans>
      );
    }

    if (data.typeProduit === "Cosmétique" && data.aucun !== true) {
      message = (
        <Trans>
          En tant que professionnel(le) de santé, vous avez l'obligation légale
          de déclarer l'effet indésirable grave d'un cosmétique.
        </Trans>
      );
    }

    if (data.typeProduit === "Dispositif médical" && data.aucun !== true) {
      message = (
        <Trans>
          En tant que professionnel(le) de santé, vous avez l'obligation légale
          de déclarer l'effet indésirable grave d'un dispositif médical.
        </Trans>
      );
    }

    if (!message) return null;

    return (
      <div className="infoTextPro">
        <p>{message}</p>
      </div>
    );
  };

  return (
    <Grid>
      <Grid>
        <TextField
          className="dataForm"
          select
          id="sex"
          fullWidth
          value={data.sex}
          onChange={handleChangeDropdownSex}
          label={<Trans>Sexe *</Trans>}
        >
          <MenuItem value={"Femme"}>
            <Trans>Femme</Trans>
          </MenuItem>
          <MenuItem value={"Homme"}>
            <Trans>Homme</Trans>
          </MenuItem>
          <MenuItem value={"Non connu"}>
            <Trans>Inconnu</Trans>
          </MenuItem>
        </TextField>
        <TextField
          className="dataForm"
          select
          id="trancheAge"
          fullWidth
          value={data.trancheAge}
          onChange={handleChangeDropdownAge}
          label={<Trans>Tranche d'âge *</Trans>}
        >
          <MenuItem value={"0-27 jours"}>
            <Trans>0-27 jours</Trans>
          </MenuItem>
          <MenuItem value={"1-23 mois"}>
            <Trans>1-23 mois</Trans>
          </MenuItem>
          <MenuItem value={"2-11 ans"}>
            <Trans>2-11 ans</Trans>
          </MenuItem>
          <MenuItem value={"12-17 ans"}>
            <Trans>12-17 ans</Trans>
          </MenuItem>
          <MenuItem value={"18-44 ans"}>
            <Trans>18-44 ans</Trans>
          </MenuItem>
          <MenuItem value={"45-64 ans"}>
            <Trans>45-64 ans</Trans>
          </MenuItem>
          <MenuItem value={"65-74 ans"}>
            <Trans>65-74 ans</Trans>
          </MenuItem>
          <MenuItem value={"+ 75 ans"}>
            <Trans>+ 75 ans</Trans>
          </MenuItem>
          <MenuItem value={"Inconnu"}>
            <Trans>Inconnu</Trans>
          </MenuItem>
        </TextField>

        <DesktopDatePicker
          className="dataForm"
          id="datePrise"
          inputFormat="DD/MM/YYYY"
          value={data.datePrise}
          onChange={handleChangeDatePrise}
          label={<Trans>Date d'utilisation du produit *</Trans>}
          renderInput={(params) => {
            return (
              <TextField
                fullWidth
                {...params}
                error={
                  data.datePrise !== null &&
                  (!data.datePrise.isValid() ||
                    !data.datePrise.isSameOrBefore())
                }
              />
            );
          }}
          maxDate={new Date()}
        />
        <DesktopDatePicker
          className="dataForm"
          id="dateEffet"
          inputFormat="DD/MM/YYYY"
          value={data.dateEffet}
          onChange={handleChangeDateEffet}
          label={<Trans>Date de l'effet indésirable *</Trans>}
          renderInput={(params) => {
            return (
              <TextField
                fullWidth
                {...params}
                error={
                  data.dateEffet !== null &&
                  (!data.dateEffet.isValid() ||
                    !data.dateEffet.isSameOrBefore() ||
                    (data.datePrise !== null &&
                      !data.dateEffet.isSameOrAfter(data.datePrise)))
                }
              />
            );
          }}
          minDate={data.datePrise ? data.datePrise : null}
          maxDate={new Date()}
        />
      </Grid>

      <p className="robotoFontB">
        <Trans>Effets indésirables (5 maximum)</Trans>
      </p>
      <Grid>
        {data.typeEffet.map((v, i) => {
          if (i < 5) {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="nomProduit"
                  options={displayList(i18n.language)}
                  onChange={(e, selectedOption) =>
                    handleChangeDropdownEffet(selectedOption, i)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  fullWidth
                  value={data.typeEffet[i]}
                  className="dataForm"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={
                        i === 0 ? (
                          <Trans>Effet indésirable *</Trans>
                        ) : (
                          <Trans>Effet indésirable</Trans>
                        )
                      }
                    />
                  )}
                />
                {i > 0 ? (
                  <div
                    onClick={() => handleRemoveEffect(i)}
                    style={{ cursor: "pointer" }}
                  >
                    <CrossIcon />
                  </div>
                ) : null}
              </div>
            );
          }
          return null;
        })}
        {data.typeEffet.length < 5 ? (
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <p
              onClick={() => handleAddEffect()}
              style={{
                alignSelf: "end",
                cursor: "pointer",
                textAlign: "end",
                marginTop: "0",
                marginBottom: "1em",
              }}
            >
              <b>+</b> <Trans>Ajouter un autre effet</Trans>
            </p>
          </div>
        ) : null}
      </Grid>
      <TextField
        multiline
        rows={6}
        fullWidth
        id="descEffet"
        type="text"
        value={data.descEffet}
        onChange={handleChangeInput}
        label={<Trans>Description de l'effet indésirable *</Trans>}
      />
      <p className="dataMainBoxTitle">
        <Trans>Gravité de l'effet indésirable</Trans>
      </p>
      <Grid className="dataMainBox blueBox">
        <label>
          <Trans>
            L'effet indésirable a-t'il entrainé (cocher la ou les cases) : *
          </Trans>
        </label>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox
            type="checkbox"
            id="hospitalisation"
            checked={data.hospitalisation}
          />
          <p id="hospitalisation">
            <Trans>une hospitalisation</Trans>
          </p>
        </Grid>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox type="checkbox" id="incapacite" checked={data.incapacite} />
          <p id="incapacite">
            <Trans>une incapacité fonctionnelle permanente ou temporaire</Trans>
          </p>
        </Grid>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox type="checkbox" id="handicap" checked={data.handicap} />
          <p id="handicap">
            <Trans>un handicap</Trans>
          </p>
        </Grid>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox type="checkbox" id="anomalies" checked={data.anomalies} />
          <p id="anomalies">
            <Trans>des anomalies congénitales</Trans>
          </p>
        </Grid>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox
            type="checkbox"
            id="risqueVital"
            checked={data.risqueVital}
          />
          <p id="risqueVital">
            <Trans>un risque vital immédiat ou un décès</Trans>
          </p>
        </Grid>
        <Grid className="formRow" onClick={handleChangeInput}>
          <Checkbox type="checkbox" id="aucun" checked={data.aucun} />
          <p id="aucun">
            <Trans>aucun de ces points</Trans>
          </p>
        </Grid>
      </Grid>
      <p className="requiredMessage">
        <Trans>* Champs obligatoires</Trans>
      </p>
      {renderMessage()}

      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
          <Button color="inherit" onClick={handlePrev} sx={{ mr: 1 }}>
            <Trans>Retour</Trans>
          </Button>

          <Button
            variant="contained"
            color="blue"
            onClick={handleNext}
            disabled={
              data.descEffet === "" ||
              data.typeEffet[0] === "" ||
              data.dateEffet === null ||
              data.datePrise === null ||
              data.trancheAge === "" ||
              !data.datePrise.isValid() ||
              !data.datePrise.isSameOrBefore() ||
              (data.dateEffet !== null &&
                (!data.dateEffet.isValid() ||
                  !data.dateEffet.isSameOrBefore() ||
                  (data.datePrise !== null &&
                    !data.dateEffet.isSameOrAfter(data.datePrise)))) ||
              (data.hospitalisation !== true &&
                data.incapacite !== true &&
                data.handicap !== true &&
                data.anomalies !== true &&
                data.risqueVital !== true &&
                data.aucun !== true)
            }
          >
            <Trans>Suivant</Trans>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
