import axios from "axios";

import { config } from "../config";

const instance = axios.create({
  withCredentials: true,
});

const BASE_URL = config.apiBaseUrl;

export const searchProduct = async (data) => {
  return instance.post(`${BASE_URL}/search-product`, data);
};

export const fetchProduct = async (data) => {
  return instance.post(`${BASE_URL}/fetch-product`, data);
};

export const fetchNbReports = async (product) => {
  return instance.post(`${BASE_URL}/fetch-nb-reports/${product}`);
};

export const searchDrugs = async (data) => {
  return instance.post(`${BASE_URL}/search-drugs`, data);
};
