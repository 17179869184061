import { ThemeProvider, Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { Theme } from "../../styles/styles";
import "./advertisement.css";

export default class DisplayAd extends React.Component {
  constructor() {
    super();
    this.state = {
      activeBtn: false,
    };
    this.scriptElement = null;
  }

  componentDidMount() {
    // Script to generate ad Monetag
    this.scriptElement = document.createElement("script");
    this.scriptElement.src = "//thubanoa.com/1?z=6917172";
    this.scriptElement.async = false;
    document.body.appendChild(this.scriptElement);

    setTimeout(() => this.setState({ activeBtn: true }), 5000);
  }

  componentWillUnmount() {
    if (this.scriptElement) {
      document.body.removeChild(this.scriptElement);
    }
  }

  render() {
    const { activeBtn } = this.state;

    return (
      <ThemeProvider theme={Theme}>
        <section className="advertisement">
          <p>
            {activeBtn ? (
              <Trans>Merci d'avoir regardé la publicité</Trans>
            ) : (
              <Trans>Publicité</Trans>
            )}
          </p>
          <Link to="/premium" target="_blank">
            <Button className="pubPageLabel" color="premium" variant="outlined">
              <Trans>
                Vous pouvez à tout moment devenir premium afin de ne plus avoir
                de publicité.
              </Trans>
            </Button>
          </Link>
          <Link to="/">
            <Button
              disabled={!activeBtn}
              variant="contained"
              color="blue"
              className="back-button"
            >
              <Trans>Retour Accueil</Trans>
            </Button>
          </Link>
        </section>
      </ThemeProvider>
    );
  }
}
