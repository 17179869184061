import { Grid, TextField, Box, Button } from "@mui/material";
import React from "react";

export default class LotMedicament extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          numLot: properties.data.numLot,
          service: properties.data.service,
          pharmacie: properties.data.pharmacie,
        },
      };
    } else {
      this.state = {
        data: {
          numLot: "",
          service: "",
          pharmacie: "",
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }
  handleChange(event_) {
    switch (event_.target.id) {
      case "numLot":
        this.setState({
          data: { ...this.state.data, numLot: event_.target.value },
        });
        break;
      case "service":
        this.setState({
          data: { ...this.state.data, service: event_.target.value },
        });
        break;
      case "pharmacie":
        this.setState({
          data: { ...this.state.data, pharmacie: event_.target.value },
        });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">{"Médicament dérivé du sang ou vaccin"}</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <form className="inputForm">
          <Grid>
            <p className="robotoFont">
              En cas d'administration de médicament(s) biologique(s) par exemple{" "}
              <b>médicament dérivé du sang ou vaccin</b>, indiquer leurs numéros
              de lot :{" "}
            </p>
            <TextField
              fullWidth
              id="numLot"
              type="text"
              value={data.numLot}
              onChange={this.handleChange}
              label="Numéro de lot"
            />
          </Grid>
          <Grid>
            <p className="robotoFont">
              Service hospitalier dans lequel le produit a été administré :{" "}
            </p>
            <TextField
              fullWidth
              id="service"
              type="text"
              value={data.service}
              onChange={this.handleChange}
              label="Service hospitalier"
            />
          </Grid>
          <Grid>
            <p className="robotoFont">Pharmacie qui a délivré le produit :</p>
            <TextField
              fullWidth
              id="pharmacie"
              type="text"
              value={data.pharmacie}
              onChange={this.handleChange}
              label="Pharmacie"
            />
          </Grid>
        </form>

        <Box className="bottomBtnForm">
          <Button
            variant="contained"
            color="blue"
            onClick={() => this.onSubmit()}
          >
            {data.numLot === "" ? "PASSER" : "CONFIRMER"}
          </Button>
        </Box>
      </>
    );
  }
}
