import axios from "axios";

import { config } from "../config";

const instance = axios.create({
  withCredentials: true,
});

const BASE_URL = config.apiBaseUrl;

export const newImputabilityTest = async (data) => {
  return instance.post(`${BASE_URL}/imputabilite`, data);
};

export const getAllImputabilityTests = async (id) => {
  return instance.get(`${BASE_URL}/imputabilite`);
};

export const newDeclaration = async (data) => {
  return instance.post(`${BASE_URL}/declaration`, data);
};

export const getAllDeclarations = async (id) => {
  return instance.get(`${BASE_URL}/declaration`);
};

export const newSearch = async (data) => {
  return instance.post(`${BASE_URL}/recherche`, data);
};

export const getAllSearchs = async (id) => {
  return instance.get(`${BASE_URL}/recherche`);
};
