import { Button, Grid, ThemeProvider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { getUser } from "../../api/user";
import {
  ConnectionProVisual,
  VigicareLogo,
  VigicareLogoWhite,
} from "../../styles/Icons";
import { Theme } from "../../styles/styles";

import "./auth.css";

class ConnexionPro extends React.Component {
  constructor() {
    super();
    this.state = {
      activePage: 0,
    };

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.props.queryUser.isSuccess) {
      this.props.navigation("/");
    }
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <div className="mainAuth">
            <header className="headerAuth">
              <Grid>
                <Link to="/">
                  <VigicareLogoWhite />
                  <VigicareLogo />
                </Link>
              </Grid>
            </header>
            <div className="authDisplay">
              <ConnectionProVisual />
              <div className="dataAuth authDisplayText">
                <Grid>
                  <p className="titleAuth">
                    <Trans>Prenez soin de vos patients en toute sécurité</Trans>
                  </p>
                </Grid>
                <Grid>
                  <p className="textAuth">
                    <Trans>
                      Fatigue, boutons, maux de tête, douleurs ? Votre patient à
                      des symptômes et vous ne savez pas si c'est lié à son
                      traitement ? Vigicare est là pour vous aider !
                    </Trans>
                  </p>
                </Grid>
                <div className="bottomBtnQuestionnaire authDisplayXL">
                  <Link to="/inscription?p=true" className="linkButton">
                    <Button
                      variant="contained"
                      color="white"
                      sx={{
                        textTransform: "none !important",
                        color: "#239497",
                        backgroundColor: "#fff",
                        marginBottom: "16px",
                        padding: "10px 0px 10px 0px",
                        marginTop: "5em",
                      }}
                    >
                      <Trans>Créer un compte</Trans>
                    </Button>
                  </Link>
                  <Link to={`/auth`} className="linkButton">
                    <Button
                      variant="outlined"
                      color="white"
                      sx={{
                        textTransform: "none !important",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <Trans>Connexion</Trans>
                    </Button>
                  </Link>
                </div>
                <div className="bottomBtnQuestionnaire bottomBtnCo authDisplayM">
                  <Link to="/inscription?p=true" className="linkButton">
                    <Button
                      variant="contained"
                      color="white"
                      sx={{
                        textTransform: "none !important",
                        color: "#239497",
                        backgroundColor: "#fff",
                        marginBottom: "16px",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <Trans>Créer un compte</Trans>
                    </Button>
                  </Link>
                  <Link to={`/auth`} className="linkButton">
                    <Button
                      variant="outlined"
                      color="white"
                      sx={{
                        textTransform: "none !important",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <Trans>Connexion</Trans>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation()(ConnexionPro)));

function withRouter(Child) {
  return (properties) => {
    const navig = useNavigate();
    return <Child {...properties} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );
    return <Child {...properties} queryUser={queryUser} />;
  };
}
