import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Grid, Box, Button, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getUser } from "../../api/user";
import "./premium.css";
import { CrossIcon } from "../../styles/Icons";

class PremiumSuccess extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      loading: false,
      success: null,
      navig: false,
    };
  }

  componentDidUpdate() {
    if (this.props.queryUser.isLoading && !this.state.loading) {
      this.setState({ loading: true });
    }

    if (
      this.props.queryUser.isSuccess &&
      this.props.queryUser.data[0].dateFin &&
      this.state.success !== true
    ) {
      this.setState({ loading: false, success: true });
    }

    if (this.props.queryUser.isError && this.state.success !== false) {
      this.setState({ loading: false, success: false });
    }

    if (this.state.navig === true) {
      this.props.handler();
      this.props.navigation("/recherche");
    }
  }

  render() {
    const { success, loading } = this.state;
    const { t } = this.props;

    return (
      <Grid>
        <Grid>
          <Grid className="premiumTopRow">
            <Button
              disabled
              sx={{
                color: "white !important",
                background: "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                textTransform: "unset !important",
                fontSize: "10px",
              }}
            >
              <AutoAwesomeRoundedIcon fontSize="12px" color="white" />
              {t("app:common.vigicarePremium")}
            </Button>
            <div onClick={this.props.handler}>
              <CrossIcon color="black" />
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "0.5rem auto" }} />
        ) : (
          <>
            {success ? (
              <>
                <p className="successPremiumTitle">
                  {t("premium.subscription.message.success")} 🎉
                </p>
                <p className="successPremiumLabel">
                  {t("premium.subscription.message.search")}
                </p>
                <Box className="successPremiumBtn">
                  <Button
                    onClick={() => this.setState({ navig: true })}
                    sx={{
                      color: "white !important",
                      background:
                        "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                      textTransform: "unset !important",
                    }}
                  >
                    {t("premium.action.search")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {!success ? (
                  <p className="errorText">
                    {t("premium.subscription.message.error")}
                  </p>
                ) : null}
              </>
            )}
          </>
        )}
      </Grid>
    );
  }
}

export default withRouter(
  withQuery(withTranslation("premium")(PremiumSuccess))
);

function withRouter(Child) {
  return (properties) => {
    const [searchParameters] = useSearchParams();
    const sessionId = searchParameters.get("session_id");

    const navigate = useNavigate();
    return (
      <Child {...properties} sessionId={sessionId} navigation={navigate} />
    );
  };
}

function withQuery(Child) {
  return (properties) => {
    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );

    return <Child {...properties} queryUser={queryUser} />;
  };
}
