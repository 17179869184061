import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { recoverPassword } from "../../api/user";
import { Theme } from "../../styles/styles";

import "./auth.css";

class MotdepasseOublie extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      email: "",
      msg: "",
    };

    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    const { t } = this.props;

    if (
      (this.props.mutationRecover.isSuccess ||
        this.props.mutationRecover.isError) &&
      this.state.msg === ""
    ) {
      this.setState({ msg: t("auth.forgotPassword.info") });
    }
  }

  handleChange(event_) {
    switch (event_.target.id) {
      case "email":
        this.setState({ email: event_.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const { email, msg } = this.state;
    const { t, mutationRecover } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <header className="headerSignIn">
            <div></div>
            <Grid>
              <p>{t("app:common.vigicare")}</p>
            </Grid>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </header>
          <div className="mainSignIn">
            <p className="authTitle">{t("auth.forgotPassword.title")}</p>
            <TextField
              fullWidth
              id="email"
              type="text"
              value={email}
              onChange={this.handleChange}
              label={t("auth.forgotPassword.label")}
              sx={{ marginTop: "12px" }}
              error={
                email !== null &&
                email !== "" &&
                email.match(/^\S+@\S+\.\S+$/) === null
              }
              helperText={
                email !== "" &&
                email !== null &&
                email.match(/^\S+@\S+\.\S+$/) === null ? (
                  <Trans>Format d'email incorrect</Trans>
                ) : null
              }
            />

            {mutationRecover.isLoading ? (
              <Grid sx={{ textAlign: "center", marginTop: "0.5rem" }}>
                <CircularProgress />
              </Grid>
            ) : null}
            {msg !== "" && !mutationRecover.isLoading ? (
              <Box sx={{ padding: "1em 0.5em" }}>
                <p>{msg}</p>
              </Box>
            ) : null}

            <Box className="bottomBtnQuestionnaire bottomBtnAuth">
              <Button
                disabled={
                  email === "" || email.match(/^\S+@\S+\.\S+$/) === null
                }
                onClick={() => mutationRecover.mutate({ email })}
                variant="contained"
                color="blue"
                sx={{
                  textTransform: "unset !important",
                  marginBottom: "16px",
                  padding: "10px 0px 10px 0px",
                }}
              >
                {t("auth.action.send")}
              </Button>
              <Link to="/">
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "unset !important",
                    marginBottom: "16px",
                    padding: "10px 0px 10px 0px",
                  }}
                >
                  {t("auth.action.back")}
                </Button>
              </Link>
            </Box>
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation("auth")(MotdepasseOublie)));

function withRouter(Child) {
  return (properties) => {
    const params = useParams();
    const navig = useNavigate();
    return <Child {...properties} params={params} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const mutationRecover = useMutation({
      mutationFn: (data) => {
        return recoverPassword(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    return <Child {...properties} mutationRecover={mutationRecover} />;
  };
}
