import HomeIcon from "@mui/icons-material/Home";
import { Grid, ThemeProvider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { Theme } from "../../styles/styles";

export default class CGU extends React.Component {
  render() {
    return (
      <ThemeProvider theme={Theme}>
        <header className="headerApp">
          <Grid className="headerImputabilite">
            <div></div>

            <p className="headerText">CGU</p>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </Grid>
        </header>
        <Grid margin={"3em"}>
          <h2>CONDITIONS GENERALES D'UTILISATION</h2>
          <br />
          Date de dernière mise à jour: Avril 2023
          <br />
          Avertissement : L'analyse d'imputabilité proposée par VITAREGUL ne se
          substitue pas à l'avis d'un professionnel de santé habilité. En cas
          d'urgence médicale le patient doit contacter immédiatement son
          professionnel de santé ou composer un numéro d'urgence local, tel que
          le 15 ou le 112 pour la France.
          <br />
          <br />
          <h4>ARTICLE 1. OBJET - CHAMP D'APPLICATION</h4>
          La société VITAREGUL CONSULTING, Société par action simplifiée,
          enregistrée au RCS de MULHOUSE, sous le numéro 912 237 864, dont le
          siège social est situé 15 Rue des Sorbiers, 68510 Sierentz (ci-après,
          « VITAREGUL ou « nous ») a conçu et développé une solution permettant
          d'analyser l'imputabilité d'un symptôme à une produit de santé, de
          réaliser une déclaration d'effet indésirable en ligne et de consulter
          les évènements indésirables déclarés sur certains produits de santé
          (ci-après, les « Services »). Les présentes Conditions Générales
          d'Utilisation (ci-après, les « CGU ») s'adressent aux personnes
          extérieures à notre organisation avec lesquelles nous interagissons, y
          compris les visiteurs de notre site internet vigicare.io (ci-après, le
          « Site ») et/ou de l'application VIGICARE (ci-après, l' « Application
          ») (ensemble, la « Plateforme ») et les utilisateurs des services
          proposés par VITAREGUL à titre gratuit et/ou à titre payant (ci-après,
          ensemble les « Utilisateurs »), ou des candidats (conjointement, «
          vous »). Elles ont pour objet de définir les modalités et conditions
          d'utilisation des Services. Les CGU sont rendues accessibles à tout
          moment à partir de l'onglet « CGU » de la Plateforme. La version
          actuellement en ligne des CGU est la seule opposable pendant toute la
          durée d'utilisation des Services et jusqu'à ce qu'une nouvelle version
          la remplace.
          <br />
          <br />
          <h4>ARTICLE 2. ACCEPTATION ET MODIFICATION DES CGU</h4>
          3.1 Acceptation des CGU L'accès et l'utilisation de la Plateforme
          ainsi que l'utilisation des Services sont soumis à acceptation pleine
          et entière, par l'Utilisateur, des présentes CGU. En cochant la case
          prévue à cet effet, l'Utilisateur déclare avoir pris pleinement
          connaissance et avoir accepté les CGU, lesquelles lui sont dès lors
          opposables, sans réserve. 3.2 Non-respect des CGU En cas de
          non-respect des CGU, VITAREGUL pourra bloquer l'accès au Service d'un
          Utilisateur, sans préjudice de tous dommages et intérêts que pourrait
          solliciter VITAREGUL. 3.3 Modification des CGU VITAREGUL se réserve la
          possibilité de modifier et de mettre à jour les CGU à tout moment. Les
          CGU modifiées seront accessibles à partir de l'onglet « CGU » du Site
          et devront faire l'objet d'une nouvelle acceptation par l'Utilisateur
          avant toute utilisation du Service.
          <br />
          <br />
          <h4>ARTICLE 4. DESCRIPTION DU SERVICE</h4>
          4.1 Finalité des Services La Plateforme permet aux Utilisateurs de
          procéder à l'analyse de l'imputabilité de symptômes/maux à un produit
          de santé consommé ou utilisé. La Plateforme permet également de
          déclarer un effet indésirable aux autorités réglementaires. Elle
          permet enfin de consulter, sous réserve de souscrire à la version
          Premium, les évènements indésirables associés à un produit donné.
          L'analyse d'imputabilité réalisée sur la Plateforme est communiquée à
          titre indicatif et ne dispense pas d'avis médical. Le traitement en
          cours ne doit pas être interrompu sans l'avis d'un professionnel
          médical. Les conclusions d'imputabilité ne sauraient engager la
          responsabilité de VITAREGUL. 4.2 Espace personnel / compte Utilisateur
          En créant un espace personnel, l'Utilisateur s'engage à fournir des
          informations sincères et exactes concernant son état civil et ses
          coordonnées. L'accès à l'espace personnel est protégé par mot de
          passe, que l'Utilisateur s'engage à garder strictement confidentiel et
          personnel. Tout Utilisateur régulièrement inscrit pourra également
          solliciter sa désinscription en se rendant à la page dédiée sur son
          espace personnel. Celle-ci sera effective dans un délai raisonnable.
          <br />
          <br />
          <h4>ARTICLE 5. ACCES AU SERVICE</h4>
          5.1 Configuration minimale Le Service est accessible dans les
          conditions définies aux présentes à tout Utilisateur disposant d'un
          accès Internet (3G, 4G, 5G ou WIFI) et d'un équipement informatique
          (PC, MAC, tablette, smartphone...) ou d'un équipement de téléphonie.
          L'Utilisateur est seul responsable du bon fonctionnement de son
          équipement informatique, de son accès Internet ainsi que de son
          équipement de téléphonie. La configuration minimale requise pour
          l'accès aux Services est la suivante : - Systèmes d'exploitation :
          Microsoft Windows (8.1 ou plus récent) ou Mac OS (10.14 ou plus
          récent) ; - Navigateurs Web : Google Chrome, Mozilla Firefox ou Apple
          Safari. Ces configurations techniques minimales sont susceptibles
          d'être modifiées sans préavis. L'Utilisateur est seul responsable de
          la compatibilité entre son équipement avec les Services. VITAREGUL
          décline toute responsabilité en cas de dysfonctionnement des Services
          dû au non- respect de la configuration requise, y compris si
          l'incompatibilité résulte en tout ou partie d'une modification de la
          configuration minimale requise. 5.2 Disponibilité L'Utilisateur
          reconnait être averti et conscient des aléas techniques inhérents à
          Internet, difficultés de connexion et des interruptions d'accès qui
          peuvent en résulter et dont VITAREGUL ne saurait être responsable.
          Plus généralement, VITAREGUL ne peut être tenue pour responsable en
          cas de perturbation du réseau Internet et/ou du réseau mobile,
          affectant l'utilisation des Services. En conséquence, VITAREGUL ne
          pourra être tenue responsable de ces risques et de leurs conséquences,
          dommageables pour l'Utilisateur, quelle qu'en soit l'ampleur. Il
          appartient aux Utilisateurs de prendre les mesures appropriées de
          façon à protéger leurs propres données et appareils de la
          contamination par des virus ou autres formes d'attaques qui pourraient
          circuler à travers la Plateforme. 5.3 Accessibilité Le Service est
          accessible 24H/24 et 7J/7, sous réserve de la survenance d'un cas de
          force majeure ou d'un événement hors du contrôle de VITAREGUL et sauf
          interruption, suspension ou limitation dans le cadre d'opérations de
          maintenance et/ou de mise à jour nécessaires au bon fonctionnement du
          Service ou pour toute autre raison, notamment technique. VITAREGUL ne
          peut être tenue responsable des cas d'interruption de l'accès aux
          Services en cas d'opération de maintenance, de mise à jour ou
          d'amélioration technique. VITAREGUL fait ses meilleurs efforts pour
          que l'accès au Service par l'Utilisateur soit ouvert. L'Utilisateur
          est informé que VITAREGUL peut être amenée, en raison de ses
          contraintes d'exploitation, à interrompre temporairement, à tout
          moment, l'accès total ou partiel au Service, avec ou sans préavis, ce
          qu'il accepte. D'une façon générale, VITAREGUL ne saurait garantir
          l'Utilisateur que la Plateforme sera exempt d'anomalies, d'erreurs ou
          de bugs, ni que ceux-ci pourront être corrigées, ni que la Plateforme
          fonctionne sans interruption ni panne. 5.4 Accès Premium Le Service de
          consultation des évènements indésirables associés à un produit n'est
          accessible qu'aux utilisateurs Premium, moyennant le paiement d'un
          abonnement mensuel. Les autres Services sont gratuits pour les
          Utilisateurs.
          <br />
          <br />
          <h4>ARTICLE 6. SECURITE DE L'INFORMATION</h4>
          VITAREGUL est responsable de la sécurité des informations stockées et
          traitées dans le cadre des Services. VITAREGUL mettra en œuvre les
          mesures de sécurité requises pour maintenir l'intégrité, la
          confidentialité et la disponibilité des données traitées via la
          Plateforme. L'Utilisateur est tenu de s'assurer que les Services sont
          utilisés de manière appropriée, notamment en : - s'assurant que les
          appareils utilisés pour accéder aux Services sont sécurisés, par
          exemple en utilisant un logiciel anti-virus ; - mettant en œuvre des
          mesures de sécurité des informations de base, par exemple en utilisant
          des mots de passe forts, en accédant aux Services dans des endroits
          sécurisés ou en utilisant un gestionnaire de mots de passe.
          <br />
          <br />
          <h4>ARTICLE 7. RESPONSABILITÉ</h4>
          7.1 Responsabilité de VITAREGUL VITAREGUL est tenue à une obligation
          de moyens concernant l'accessibilité et la disponibilité du Service.
          En tout état de cause, la responsabilité de VITAREGUL est limitée à la
          transmission du formulaire de déclaration d'effet indésirable aux
          autorités compétentes, sous réserve que l'Utilisateur soit allé au
          bout du processus de déclaration et que le défaut de transmission soit
          dû à une défaillance fautive de VITAREGUL, sauf cas de force majeure
          au sens de la jurisprudence des tribunaux français. En particulier,
          VITAREGUL n'est pas responsable en cas de mauvaise interprétation des
          résultats d'imputabilité fournis via la Plateforme aux Utilisateurs.
          Malgré des mises à jour régulières, la Plateforme ne peut être tenu
          responsable de la modification des dispositions administratives et
          juridiques survenant après la publication. Dans les limites permises
          par le droit français, VITAREGUL ne peut en aucun cas être tenu
          responsable des pertes et dommages indirects ou imprévisibles subis
          par l'Utilisateur. 7.2 Responsabilité de l'Utilisateur Les
          Utilisateurs sont responsables des informations qu'ils saisissent sur
          la plateforme et de leur exactitude. Les Utilisateurs s'engagent à
          utiliser les Services de manière normale, conformément à leur
          finalité, aux CGU et aux dispositions réglementaires et légales. Toute
          utilisation à des fins commerciales et publicitaires est strictement
          interdite. Toute utilisation frauduleuse du Service par l'Utilisateur
          ou qui contreviendrait aux présentes CGU pourra entraîner le blocage
          du Service. L'Utilisateur est également responsable des conséquences
          pécuniaires qui pourraient résulter de l'utilisation fautive du
          Service, sauf en cas d'utilisation frauduleuse extérieure dûment
          prouvée ne résultant pas d'une faute ou d'une négligence de
          l'Utilisateur. Les Utilisateurs s'engagent à utiliser les Services
          avec loyauté et s'interdisent de : - faire du « reverse engineering »,
          décompiler, désassembler ou tenter de toute autre manière de découvrir
          le code source, le code objet ou la structure sous-jacente, les idées
          ou les algorithmes de l'un des Services ; - copier, louer, donner en
          crédit-bail, distribuer, donner en gage, céder ou transférer ou grever
          de toute autre manière des droits sur les Services ; - utiliser les
          Services d'une manière susceptible d'endommager, de désactiver, de
          surcharger, d'altérer ou d'interférer de toute autre manière avec la
          fourniture par VITAREGUL des Services, - accéder aux Service de
          manière à contrôler la disponibilité, la performance ou la
          fonctionnalité, ou pour toute autre évaluation comparative, d'une
          manière qui interfère matériellement avec le fonctionnement ou la
          performance des Services; - utiliser les Services à des fins de
          concurrence ou pour copier tout ou partie des Services; - tenter de
          commettre tout acte de contrefaçon à l'encontre de tiers, par quelque
          moyen que ce soit ; ou - détourner l'objet des Services pour commettre
          des crimes, délits ou contraventions réprimés par la loi.
          <br />
          <br />
          <h4>ARTICLE 9. DROITS DE PROPRIÉTÉ INTELLECTUELLE</h4>
          9.1 License d'utilisation du Service Sous réserve du respect des
          présentes CGU, VITAREGUL accorde à l'Utilisateur une licence, limitée,
          non exclusive, non cessible et sans droit de sous-licence, d'accès et
          d'utilisation de la Plateforme aux fins d'utilisation du Service, via
          l'interface graphique proposée. Cette licence est strictement
          personnelle et non sous-licenciable. Le scraping ou la connexion
          directe à l'API développée par VITAREGUL sont strictement interdits
          sauf accord exprès et préalable de VITAREGUL. 9.2 Propriété du contenu
          du Site La Plateforme, ainsi que tous les éléments graphiques qu'il
          contient (notamment les logos, images, textes, photographies), la
          documentation, les bases de données, les logiciels sont la propriété
          exclusive de VITAREGUL, à l'exception du formulaire légal de
          déclaration des effets indésirables. Les Utilisateurs reconnaissent
          que VITAREGUL est titulaire de droits de propriété intellectuelle sur
          la Plateforme et les éléments graphique qu'elle contient. La
          structure, l'organisation et le code de la Plateforme sont les secrets
          commerciaux et les informations confidentielles de VITAREGUL.
          L'Utilisation de la Plateforme ne confère à l'Utilisateur aucun droit
          de propriété sur cette dernière ni aucun des éléments qui y figurent.
          Toute reproduction totale ou partielle, modification ou utilisation de
          ces éléments, pour quelque motif et sur quelque support que ce soit,
          sans l'accord exprès et préalable de VITAREGUL, est strictement
          interdite. 9.3 Coopération des parties Les Utilisateurs s'engagent à
          ne pas porter atteinte directement ou indirectement aux droits de
          propriété intellectuelle de VITAREGUL. L'Utilisateur s'engage à : a)
          protéger les Services contre la contrefaçon, le détournement, le vol,
          l'utilisation abusive, la concurrence déloyale ou l'accès non autorisé
          ; b) prendre, aux frais de VITAREGUL, toutes les mesures que VITAREGUL
          peut raisonnablement exiger pour l'aider à maintenir la validité,
          l'applicabilité et sa titularité sur les droits de propriété
          intellectuelle des Services ; et c) informer VITAREGUL par écrit dans
          les plus brefs délais dès qu'ils ont connaissance : (i) d'une
          violation réelle ou présumée des droits de propriété intellectuelle de
          VITAREGUL; ou (ii) de toute réclamation selon laquelle les Services,
          en tout ou en partie, violeraient, détourneraient ou porteraient
          atteinte, de toute manière, aux droits de propriété intellectuelle ou
          autres droits d'un tiers ; d) coopérer pleinement avec VITAREGUL et
          l'assister par tous les moyens raisonnables dans la conduite de toute
          réclamation ou action de VITAREGUL visant à empêcher toute violation,
          détournement ou violation réelle ou présumée des droits de propriété
          intellectuelle de VITAREGUL sur les Services.
          <br />
          <br />
          <h4>ARTICLE 10. CONVENTION DE PREUVE</h4>
          Il est expressément convenu que les données conservées dans le système
          d'information de VITAREGUL a force probante quant aux utilisations de
          l'un des Services par un Utilisateur. Ces données constituent des
          preuves qui, si elles sont produites comme moyens de preuve dans
          toutes procédures contentieuses ou précontentieuses, seront
          recevables, valables et opposables aux Utilisateurs et à VITAREGUL,
          dans les mêmes conditions et avec la même force probante que tout
          document qui serait établi, reçu ou conservé par écrit.
          <br />
          <br />
          <h4>ARTICLE 11. NOTIFICATION ET RECLAMATION</h4>
          Les réclamations que pourrait avoir un Utilisateur en lien avec son
          expérience doivent être adressées au service-client de VITAREGUL par
          courriel à l'adresse suivante contact@VITAREGUL.fr ou à l'adresse
          postale suivante.
          <br />
          <br />
          <h4>
            ARTICLE 12. DONNÉES UTILISATEURS ET PROTECTION DES DONNEES
            PERSONNELLES
          </h4>
          Les conditions de collecte et de traitement des données à caractère
          personnel des Utilisateurs et des personnes concernées par VITAREGUL
          ainsi que les droits des Utilisateurs et des personnes concernées sont
          décrits dans la Politique de Confidentialité accessible via le lien
          suivant https://app.vigicare.io/privacy .
          <br />
          <br />
          <h4>ARTICLE 13 LIENS HYPERTEXTES</h4>
          Des liens hypertextes peuvent être présents sur la Plateforme.
          L'Utilisateur est informé qu'en cliquant sur ces liens, il sortira de
          la Plateforme et que VITAREGUL n'a pas de contrôle sur les pages web
          sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
          responsable de leur contenu.
          <br />
          <br />
          <h4>ARTICLE 14 LOI APPLICABLE - REGLEMENT DES LITIGES</h4>
          Les présentes CGU sont soumises au droit français. A défaut de
          règlement amiable, tout litige relatif à leur interprétation et/ou à
          leur exécution relève de la compétence des tribunaux français
          compétents.
        </Grid>
      </ThemeProvider>
    );
  }
}
