import { Button, TextField, Grid, Box } from "@mui/material";
import React from "react";

export default class DescEffets extends React.Component {
  constructor(properties) {
    super(properties);
    if (properties.data !== undefined && properties.data.length !== 0) {
      this.state = {
        data: {
          textEffets:
            properties.data.textEffets !== undefined
              ? properties.data.textEffets
              : "",
        },
      };
    } else {
      this.state = {
        data: {
          textEffets: "",
        },
      };
    }
    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    if (event_.target.value !== undefined)
      this.setState({ data: { textEffets: event_.target.value } });
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <Grid>
          <p className="pageTitle">Description de l'effet</p>
          <Grid className="lineVectors">
            <hr className="lineVector" style={{ width }} />
            <hr className="lineVectorFull" />
          </Grid>
          <div className="inputForm">
            <TextField
              multiline
              rows={6}
              value={data.textEffets}
              label="Description et délai de survenue de l'effet indésirable"
              onChange={this.handleChange}
            />
          </div>
          <Box className="bottomBtnForm">
            <Button
              variant="contained"
              color="blue"
              onClick={() => this.onSubmit()}
            >
              {data.textEffets === "" ? "PASSER" : "CONFIRMER"}
            </Button>
          </Box>
        </Grid>
      </>
    );
  }
}
