import { Grid, Button, TextField } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

export default class DispositifMedical extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          denominationDM:
            properties.data.denominationDM !== undefined
              ? properties.data.denominationDM
              : "",
          denominationComDM:
            properties.data.denominationComDM !== undefined
              ? properties.data.denominationComDM
              : "",
          numSerieLot:
            properties.data.numSerieLot !== undefined
              ? properties.data.numSerieLot
              : "",
          vLogicielle:
            properties.data.vLogicielle !== undefined
              ? properties.data.vLogicielle
              : "",
          nomFournisseur:
            properties.data.nomFournisseur !== undefined
              ? properties.data.nomFournisseur
              : "",
          adrFournisseur:
            properties.data.adrFournisseur !== undefined
              ? properties.data.adrFournisseur
              : "",
          cpFournisseur:
            properties.data.cpFournisseur !== undefined
              ? properties.data.cpFournisseur
              : "",
          comFournisseur:
            properties.data.comFournisseur !== undefined
              ? properties.data.comFournisseur
              : "",
          nomFabricant:
            properties.data.nomFabricant !== undefined
              ? properties.data.nomFabricant
              : "",
          adrFabricant:
            properties.data.adrFabricant !== undefined
              ? properties.data.adrFabricant
              : "",
          cpFabricant:
            properties.data.cpFabricant !== undefined
              ? properties.data.cpFabricant
              : "",
          comFabricant:
            properties.data.comFabricant !== undefined
              ? properties.data.comFabricant
              : "",
        },
        errorIncomplete: false,
      };
    } else {
      this.state = {
        data: {
          denominationDM: "",
          denominationComDM: "",
          numSerieLot: "",
          vLogicielle: "",
          nomFournisseur: "",
          adrFournisseur: "",
          cpFournisseur: "",
          comFournisseur: "",
          nomFabricant: "",
          adrFabricant: "",
          cpFabricant: "",
          comFabricant: "",
        },
        errorIncomplete: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);
    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "denominationDM":
        this.setState({
          data: { ...data, denominationDM: event_.target.value },
        });
        break;
      case "denominationComDM":
        this.setState({
          data: { ...data, denominationComDM: event_.target.value },
        });
        break;
      case "numSerieLot":
        this.setState({ data: { ...data, numSerieLot: event_.target.value } });
        break;
      case "vLogicielle":
        this.setState({ data: { ...data, vLogicielle: event_.target.value } });
        break;
      case "nomFournisseur":
        this.setState({
          data: { ...data, nomFournisseur: event_.target.value },
        });
        break;
      case "adrFournisseur":
        this.setState({
          data: { ...data, adrFournisseur: event_.target.value },
        });
        break;
      case "cpFournisseur":
        this.setState({
          data: { ...data, cpFournisseur: event_.target.value },
        });
        break;
      case "comFournisseur":
        this.setState({
          data: { ...data, comFournisseur: event_.target.value },
        });
        break;
      case "nomFabricant":
        this.setState({ data: { ...data, nomFabricant: event_.target.value } });
        break;
      case "adrFabricant":
        this.setState({ data: { ...data, adrFabricant: event_.target.value } });
        break;
      case "cpFabricant":
        this.setState({ data: { ...data, cpFabricant: event_.target.value } });
        break;
      case "comFabricant":
        this.setState({ data: { ...data, comFabricant: event_.target.value } });
        break;
      default:
        break;
    }
  }

  onSubmit() {
    if (
      this.state.data.denominationDM !== "" &&
      this.state.data.denominationComDM !== "" &&
      this.state.data.numSerieLot !== "" &&
      this.state.data.nomFabricant !== "" &&
      this.state.data.adrFabricant !== "" &&
      this.state.data.cpFabricant !== "" &&
      this.state.data.comFabricant !== ""
    ) {
      this.props.handler(this.state.data);
    } else {
      window.scrollTo(0, 0);
      this.setState({ errorIncomplete: true });
    }
  }

  render() {
    const { data, errorIncomplete } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">Le dispositif médical impliqué</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        {errorIncomplete ? <p className="errorText">Champs manquants</p> : null}

        <div className="inputForm">
          <TextField
            fullWidth
            id="denominationDM"
            type="text"
            value={data.denominationDM}
            onChange={this.handleChange}
            error={errorIncomplete && data.denominationDM === ""}
            label="Dénomination commune du DM *"
          />
          <TextField
            fullWidth
            multiline
            id="denominationComDM"
            type="text"
            value={data.denominationComDM}
            onChange={this.handleChange}
            error={errorIncomplete && data.denominationComDM === ""}
            label="Dénomination commerciale : modèle/type/référence *"
          />
          <TextField
            fullWidth
            id="numSerieLot"
            type="text"
            value={data.numSerieLot}
            onChange={this.handleChange}
            error={errorIncomplete && data.numSerieLot === ""}
            label="N° de série ou de lot *"
          />
          <TextField
            fullWidth
            id="vLogicielle"
            type="text"
            value={data.vLogicielle}
            onChange={this.handleChange}
            label="Version logicielle (facultatif)"
          />

          <TextField
            fullWidth
            id="nomFournisseur"
            type="text"
            value={data.nomFournisseur}
            onChange={this.handleChange}
            label="Nom du fournisseur (facultatif)"
          />
          <TextField
            fullWidth
            id="adrFournisseur"
            type="text"
            value={data.adrFournisseur}
            onChange={this.handleChange}
            label="Adresse du fournisseur (facultatif)"
          />

          <div className="inputSmallRow">
            <TextField
              id="cpFournisseur"
              className="inputSmall"
              type="number"
              value={data.codePostal}
              onChange={this.handleChange}
              label="Code Postal"
            />
            <TextField
              id="comFournisseur"
              className="inputSmall"
              type="text"
              value={data.commune}
              onChange={this.handleChange}
              label="Commune"
            />
          </div>
          <TextField
            fullWidth
            id="nomFabricant"
            type="text"
            value={data.nomFabricant}
            error={errorIncomplete && data.nomFabricant === ""}
            onChange={this.handleChange}
            label="Nom du fabricant *"
          />
          <TextField
            fullWidth
            id="adrFabricant"
            type="text"
            value={data.adrFabricant}
            error={errorIncomplete && data.adrFabricant === ""}
            onChange={this.handleChange}
            label="Adresse du fabriquant *"
          />
          <div className="inputSmallRow">
            <TextField
              id="cpFabricant"
              className="inputSmall"
              type="number"
              value={data.cpFabricant}
              error={errorIncomplete && data.cpFabricant === ""}
              onChange={this.handleChange}
              label="Code Postal *"
            />
            <TextField
              id="comFabricant"
              className="inputSmall"
              type="text"
              value={data.comFabricant}
              error={errorIncomplete && data.comFabricant === ""}
              onChange={this.handleChange}
              label="Commune *"
            />
          </div>
        </div>
        <p className="requiredMessage">
          <Trans>* Champs obligatoires</Trans>
        </p>
        <Grid textAlign={"end"} marginBottom="24px" marginTop="24px">
          <Button
            variant="contained"
            color="blue"
            onClick={() => this.onSubmit()}
          >
            {"SUIVANT"}
          </Button>
        </Grid>
      </>
    );
  }
}
