import AddIcon from "@mui/icons-material/Add";
import { Grid, Button, Box, TextField, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { Trans } from "react-i18next";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { CrossIcon } from "../../../styles/Icons";

export default class Medicaments extends React.Component {
  constructor(properties) {
    super(properties);
    if (properties.data !== undefined && properties.data.length !== 0) {
      this.state = {
        medicaments: [...properties.data],
        showInfo: false,
      };
    } else {
      this.state = {
        medicaments: [],
        showInfo: false,
      };
    }

    this.createMed = this.createMed.bind(this);
    this.displayInfos = this.displayInfos.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
    this.handleChangeDropdownDuree = this.handleChangeDropdownDuree.bind(this);

    window.scrollTo(0, 0);
  }

  handleChangeDateStart(event_, index) {
    let meds = [...this.state.medicaments];
    let newMed = { ...meds[index] };
    newMed.startDate = event_;
    meds[index] = newMed;

    this.setState({ medicaments: meds });
  }

  handleChangeDateEnd(event_, index) {
    let meds = [...this.state.medicaments];
    let newMed = { ...meds[index] };
    newMed.endDate = event_;
    meds[index] = newMed;

    this.setState({ medicaments: meds });
  }

  handleChangeDropdown(event_, index) {
    let meds = [...this.state.medicaments];
    let newMed = { ...meds[index] };

    if (event_.target.value !== undefined) {
      newMed.administrationRoute = event_.target.value;
      meds[index] = newMed;
      this.setState({ medicaments: meds });
    }
  }

  handleChangeDropdownDuree(event_, index) {
    let meds = [...this.state.medicaments];
    let newMed = { ...meds[index] };

    if (event_.target.value !== undefined) {
      newMed.uniteDuree = event_.target.value;
      meds[index] = newMed;
      this.setState({ medicaments: meds });
    }
  }

  handleChange(event_, index) {
    let meds = [...this.state.medicaments];
    let newMed = { ...meds[index] };

    switch (event_.target.id) {
      case "name": {
        newMed.name = event_.target.value;
        meds[index] = newMed;
        this.setState({ medicaments: meds });
        break;
      }
      case "posology": {
        newMed.posology = event_.target.value;
        meds[index] = newMed;
        this.setState({ medicaments: meds });
        break;
      }
      case "indication": {
        newMed.indication = event_.target.value;
        meds[index] = newMed;
        this.setState({ medicaments: meds });
        break;
      }
      default:
        break;
    }
  }

  createMed() {
    let newMed = {
      name: "",
      posology: "",
      uniteDuree: "par jour",
      administrationRoute: "",
      startDate: null,
      endDate: null,
      indication: "",
    };
    this.setState({ medicaments: [...this.state.medicaments, newMed] });
  }

  removeMed(id) {
    this.setState({
      medicaments: this.state.medicaments.filter(function (med, index) {
        return index !== id;
      }),
    });
  }

  displayInfos() {
    this.setState((previousState) => ({ showInfo: !previousState.showInfo }));
  }

  closePopup() {
    this.setState({ showInfo: false });
  }

  onSubmit() {
    const { medicaments } = this.state;
    if (medicaments[0] !== undefined) {
      const med = medicaments[0];
      if (
        med.name !== "" &&
        med.posology !== "" &&
        med.administrationRoute !== "" &&
        med.startDate !== null &&
        med.startDate.isValid() &&
        med.startDate.isBefore()
      ) {
        if (med.endDate !== null) {
          if (
            med.endDate.isBefore() &&
            med.endDate.isValid() &&
            med.endDate.isSameOrAfter(med.startDate)
          )
            this.props.handler(this.state.medicaments);
        } else this.props.handler(this.state.medicaments);
      }
    }
  }

  render() {
    const { medicaments, showInfo } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";

    return (
      <Grid>
        <p className="pageTitle">{"Médicament(s)"}</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>
        <p>
          {" "}
          Ajouter le ou les médicaments à l'origine de l'effet indésirable :{" "}
        </p>
        <div className="medicamentsList">
          {medicaments.map((med, i) => {
            return (
              <Grid key={i}>
                {i > 0 ? <hr className="separator" /> : null}
                <div className="medTitle">
                  <p className="medTitleP">MÉDICAMENT {i + 1}</p>
                  <p onClick={() => this.removeMed(i)}>
                    <CrossIcon />
                  </p>
                </div>
                <form className="medForm">
                  <TextField
                    id="name"
                    type="text"
                    value={med.name}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Médicament *"
                  />
                  <TextField
                    select
                    id="administrationRoute"
                    className="inputDropdown"
                    label="Voie d'administration *"
                    value={med.administrationRoute}
                    onChange={(e) => this.handleChangeDropdown(e, i)}
                  >
                    <MenuItem value="Orale">Orale</MenuItem>
                    <MenuItem value="Sublinguale">Sublinguale</MenuItem>
                    <MenuItem value="Injection sous cutanée">
                      Injection sous cutanée
                    </MenuItem>
                    <MenuItem value="Injection intramusculaire">
                      Injection intramusculaire
                    </MenuItem>
                    <MenuItem value="Injection intraveineuse">
                      Injection intraveineuse
                    </MenuItem>
                    <MenuItem value="Injection intrathécale">
                      Injection intrathécale
                    </MenuItem>
                    <MenuItem value="Rectale">Rectale</MenuItem>
                    <MenuItem value="Vaginale">Vaginale</MenuItem>
                    <MenuItem value="Oculaire">Oculaire</MenuItem>
                    <MenuItem value="Auriculaire">Auriculaire</MenuItem>
                    <MenuItem value="Nasale">Nasale</MenuItem>
                    <MenuItem value="Inhalation">Inhalation</MenuItem>
                    <MenuItem value="Nébulisation">Nébulisation</MenuItem>
                    <MenuItem value="Cutanée">Cutanée</MenuItem>
                    <MenuItem value="Transdermique">Transdermique</MenuItem>
                  </TextField>
                  <Grid className="formRowDouble">
                    <TextField
                      fullWidth
                      id="posology"
                      type="number"
                      value={med.posology}
                      onChange={(e) => this.handleChange(e, i)}
                      label="Posologie *"
                    />
                    <TextField
                      select
                      value={med.uniteDuree}
                      onChange={(e) => this.handleChangeDropdownDuree(e, i)}
                      sx={{ minWidth: "45%", textAlign: "end" }}
                    >
                      <MenuItem value="par jour">par jour</MenuItem>
                      <MenuItem value="par semaine">par semaine</MenuItem>
                      <MenuItem value="par mois">par mois</MenuItem>
                    </TextField>
                  </Grid>

                  <DesktopDatePicker
                    id="startDate"
                    inputFormat="DD/MM/YYYY"
                    value={med.startDate}
                    onChange={(e) => this.handleChangeDateStart(e, i)}
                    label="Date de début de traitement *"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={
                            med.startDate !== null &&
                            (!med.startDate.isValid() ||
                              !med.startDate.isBefore())
                          }
                        />
                      );
                    }}
                  />
                  <DesktopDatePicker
                    id="endDate"
                    inputFormat="DD/MM/YYYY"
                    value={med.endDate}
                    onChange={(e) => this.handleChangeDateEnd(e, i)}
                    label="Date de fin de traitement"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={
                            (med.endDate !== null &&
                              (!med.endDate.isValid() ||
                                !med.endDate.isBefore())) ||
                            (med.startDate !== null &&
                              med.endDate !== null &&
                              !med.endDate.isSameOrAfter(med.startDate))
                          }
                        />
                      );
                    }}
                  />

                  <TextField
                    id="indication"
                    type="text"
                    value={med.indication}
                    onChange={(e) => this.handleChange(e, i)}
                    label="Indication"
                  />
                </form>
                <div
                  className="discretButton"
                  onClick={() => this.displayInfos()}
                >
                  Préciser si <u>ATU</u> ou <u>RTU</u> le cas échant
                </div>
              </Grid>
            );
          })}
          {medicaments.length > 0 ? (
            <p className="requiredMessage">
              <Trans>* Champs obligatoires</Trans>
            </p>
          ) : null}
          {medicaments.length !== undefined && medicaments.length < 5 ? (
            <Box className="boxBtn fullBtn">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.createMed()}
              >
                <AddIcon fontSize="small" sx={{ paddingRight: "12px" }} />
                {"AJOUTER UN MÉDICAMENT"}
              </Button>
            </Box>
          ) : null}
          <Popup
            open={showInfo}
            position="center"
            onClose={() => this.closePopup()}
            contentStyle={{ minWidth: "80%" }}
          >
            <Grid className="infoPanel">
              <div className="infoTitle">
                <p>
                  <b>Informations :</b>
                </p>
                <p onClick={() => this.displayInfos()}>
                  <CrossIcon />
                </p>
              </div>
              <p>
                Les ATU et RTU sont devenues depuis 2021 Autorisation d'accès
                précoce (AAP) et Autorisation d'accès compassionnel aux
                médicaments (AAC)
              </p>
              <p>ATU = Autorisaion temporaire d'utilisation</p>
              <p>RTU = Recommandation temporaire d'utilisation</p>
            </Grid>
          </Popup>

          {medicaments[0] !== undefined &&
          medicaments[0].name !== "" &&
          medicaments[0].posology !== "" &&
          medicaments[0].administrationRoute !== "" &&
          medicaments[0].startDate !== null ? (
            <Grid textAlign={"end"} paddingBottom="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.onSubmit()}
              >
                {"SUIVANT"}
              </Button>
            </Grid>
          ) : (
            <Grid textAlign={"end"} paddingBottom="24px">
              <Button variant="contained" disabled>
                {"SUIVANT"}
              </Button>
            </Grid>
          )}
        </div>
      </Grid>
    );
  }
}
