import { Grid } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import { withTranslation, Trans } from "react-i18next";

import { CrossIcon } from "../../styles/Icons";
import { formatNumber } from "../../utils/formatNumber";

const optionsBar = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const optionsPie = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

const optionsHBar = {
  maintainAspectRatio: false,
  indexAxis: "y",
  plugins: {
    legend: {
      display: false,
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Legend,
  Tooltip
);

class FicheProduitVigicare extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      name: properties.data.productName,
      nbEffets: properties.data.product.sex.reduce((accumulator, e) => {
        return accumulator + e.count;
      }, 0),
      dataBar: {
        labels: properties.data.product.sex.map((e) =>
          e.sexe !== "" ? e.sexe : "Inconnu"
        ),
        datasets: [
          {
            data: properties.data.product.sex.map((e) => e.count),
            backgroundColor: ["#071B2E", "#239497", "#AAAAAA"],
          },
        ],
      },
      dataPie: {
        labels: properties.data.product.ageGroup.map((e) =>
          e.groupeAge !== "" ? e.groupeAge : "Inconnu"
        ),
        datasets: [
          {
            data: properties.data.product.ageGroup.map((e) => e.count),
            backgroundColor: [
              "#942323",
              "#239497",
              "#F4BF4E",
              "#F28F8F",
              "#4D77FF",
              "#F56E46",
              "#AA6550",
              "#CCCCCC",
              "#071B2E",
            ],
          },
        ],
      },
      dataHBar: {
        labels: properties.data.product.types.map((e) => e.FR),
        datasets: [
          {
            data: properties.data.product.types.map((e) => e.count),
            backgroundColor: ["#239497"],
          },
        ],
      },
    };
  }

  componentDidUpdate(pP, pS) {
    if (pP.data.productName !== this.props.data.productName) {
      this.setState({
        name: this.props.data.productName,
        nbEffets: this.props.data.product.sex.reduce((accumulator, e) => {
          return accumulator + e.count;
        }, 0),
        dataBar: {
          labels: this.props.data.product.sex.map((e) =>
            e.sexe !== "" ? e.sexe : "Inconnu"
          ),
          datasets: [
            {
              data: this.props.data.product.sex.map((e) => e.count),
              backgroundColor: ["#071B2E", "#239497", "#AAAAAA"],
            },
          ],
        },
        dataPie: {
          labels: this.props.data.product.ageGroup.map((e) =>
            e.groupeAge !== "" ? e.groupeAge : "Inconnu"
          ),
          datasets: [
            {
              data: this.props.data.product.ageGroup.map((e) => e.count),
              backgroundColor: [
                "#942323",
                "#239497",
                "#F4BF4E",
                "#F28F8F",
                "#4D77FF",
                "#F56E46",
                "#AA6550",
                "#CCCCCC",
                "#071B2E",
              ],
            },
          ],
        },
        dataHBar: {
          labels: this.props.data.product.types.map((e) => e.FR),
          datasets: [
            {
              data: this.props.data.product.types.map((e) => e.count),
              backgroundColor: ["#239497"],
            },
          ],
        },
      });
    }
  }

  render() {
    const { name, dataBar, nbEffets, dataPie, dataHBar } = this.state;
    const formattednbEffets = formatNumber(nbEffets);

    return (
      <>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="productTitle">{name}</p>
          <div onClick={this.props.handler}>
            <CrossIcon />
          </div>
        </Grid>
        <Grid sx={{ textAlign: "end", fontSize: "12px" }}>
          <Trans>Données fournies par</Trans> Vigicare
        </Grid>
        <br />
        <div className="productDisplay">
          <Grid sx={{ width: "50%", height: "10em" }}>
            <h5 className="productSubtitles">
              <Trans>Effets indésirables</Trans>
            </h5>
            <p className="productDetail">
              <Trans>
                Effets indésirables différents recensés suite à l'utlisation de
                ce produit
              </Trans>
            </p>
            <p className="productData">{formattednbEffets}</p>
            <br />
          </Grid>
          <Grid sx={{ width: "50%", height: "20em" }}>
            <h5 className="productSubtitles">
              <Trans>Ratio homme - femme</Trans>
            </h5>
            <p className="productDetail"></p>
            <div className="productChart">
              <Bar
                style={{ width: "40%", alignSelf: "center" }}
                options={optionsBar}
                data={dataBar}
              />
            </div>
          </Grid>
        </div>
        <div className="productDisplay">
          <Grid>
            <h5 className="productSubtitles">
              <Trans>Proportion par tranche d'âge</Trans>
            </h5>
            <p className="productDetail"></p>
            <div className="productChart">
              <Pie
                style={{ width: "40%", height: "20em" }}
                options={optionsPie}
                data={dataPie}
              />
            </div>
          </Grid>
          <Grid>
            <h5 className="productSubtitles productList">
              <Trans>
                Nombre d'effets indésirables en fonction de la classification
              </Trans>
            </h5>
            <p className="productDetail"></p>
            <div className="productChart">
              <Bar
                style={{ width: "40%", height: "35em" }}
                options={optionsHBar}
                data={dataHBar}
              />
            </div>
          </Grid>
        </div>
      </>
    );
  }
}

export default withTranslation()(FicheProduitVigicare);
