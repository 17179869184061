import { VisibilityOff, Visibility } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { loginUser, getUser } from "../../api/user";
import { Theme } from "../../styles/styles";

import "./auth.css";

class Auth extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.props.mutationLogin.isSuccess || this.props.queryUser.isSuccess) {
      this.props.navigation("/");
    } else if (this.props.mutationLogin.isError) {
      window.scrollTo(0, 0);
    }
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleChange(event_) {
    switch (event_.target.id) {
      case "email":
        this.setState({ email: event_.target.value });
        break;
      case "password":
        this.setState({ password: event_.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const { email, password, showPassword } = this.state;
    const { mutationLogin } = this.props;
    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <header className="headerSignIn">
            <div></div>
            <Grid>
              <p>
                <Trans>Heureux de vous revoir !</Trans>
              </p>
            </Grid>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </header>
          <div className="mainSignIn">
            <p className="authTitle">
              <Trans>Connectez-vous</Trans>
            </p>

            <TextField
              fullWidth
              id="email"
              type="text"
              value={email}
              onChange={this.handleChange}
              label={<Trans>Votre email</Trans>}
              sx={{ marginTop: "12px" }}
            />
            <TextField
              fullWidth
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={this.handleChange}
              label={<Trans>Votre mot de passe</Trans>}
              sx={{ marginTop: "12px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={"#DDDDDD"}
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Link to="/mdp">
              <p className="loginTxt">
                <Trans>Mot de passe oublié ?</Trans>
              </p>
            </Link>
            {mutationLogin.error !== null &&
            mutationLogin.error.response !== undefined ? (
              <>
                {mutationLogin.error.response.data !== undefined &&
                mutationLogin.error.response.status !== 503 ? (
                  <p className="errorLabel">
                    <Trans>{mutationLogin.error.response.data}</Trans>
                  </p>
                ) : (
                  <p className="errorLabel">
                    <Trans>Erreur de connexion au serveur</Trans>
                  </p>
                )}
              </>
            ) : null}

            <Box className="bottomBtnQuestionnaire bottomBtnAuth">
              <Button
                disabled={email === "" || password === ""}
                onClick={() => mutationLogin.mutate({ email, password })}
                variant="contained"
                color="blue"
                sx={{
                  textTransform: "unset !important",
                  marginBottom: "16px",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <Trans>Connexion</Trans>
              </Button>
              <Link to={"/inscription"} className="linkButton">
                <Button
                  variant="outlined"
                  color="white"
                  sx={{
                    textTransform: "unset !important",
                    textDecorationLine: "none !important",
                    padding: "10px 0px 10px 0px",
                    color: "#000 !important",
                  }}
                >
                  <Trans>Je n'ai pas encore de compte Vigicare</Trans>
                </Button>
              </Link>
            </Box>
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation()(Auth)));

function withRouter(Child) {
  return (properties) => {
    const params = useSearchParams();
    const navig = useNavigate();
    return <Child {...properties} params={params} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (properties) => {
    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        })
    );

    const mutationLogin = useMutation({
      mutationFn: (data) => {
        return loginUser(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    return (
      <Child
        {...properties}
        mutationLogin={mutationLogin}
        queryUser={queryUser}
      />
    );
  };
}
