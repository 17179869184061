import { Button, TextField, Grid, Checkbox } from "@mui/material";
import React from "react";

export default class Localisation extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      this.state = {
        data: {
          zoneProduit: properties.data.zoneProduit,
          distanceProduit: properties.data.distanceProduit,
          peau: properties.datapeau,
          ongles: properties.data.ongles,
          cheveux: properties.data.cheveux,
          dents: properties.data.dents,
          yeux: properties.data.yeux,
          muqueuses: properties.data.muqueuses,
          oculaire: properties.oculaire,
          auriculaire: properties.data.auriculaire,
          nasale: properties.data.nasale,
          buccale: properties.data.buccale,
          pharyngée: properties.data.pharyngée,
          pulmonaire: properties.data.pulmonaire,
          génitale: properties.data.génitale,
          anale: properties.data.anale,
          respisratoire: properties.data.respisratoire,
          digestifs: properties.data.digestifs,
          généraux: properties.data.généraux,
          neurologique: properties.data.neurologique,
          otherInfos: properties.data.otherInfos,
        },
      };
    } else {
      this.state = {
        data: {
          zoneProduit: false,
          distanceProduit: false,
          peau: false,
          ongles: false,
          cheveux: false,
          dents: false,
          yeux: false,
          muqueuses: false,
          oculaire: false,
          auriculaire: false,
          nasale: false,
          buccale: false,
          pharyngée: false,
          pulmonaire: false,
          génitale: false,
          anale: false,
          respisratoire: false,
          digestifs: false,
          généraux: false,
          neurologique: false,
          otherInfos: "",
        },
      };
    }
    this.handleChange = this.handleChange.bind(this);

    window.scrollTo(0, 0);
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "zoneProduit":
        this.setState({ data: { ...data, zoneProduit: !data.zoneProduit } });
        break;
      case "distanceProduit":
        this.setState({
          data: { ...data, distanceProduit: !data.distanceProduit },
        });
        break;
      case "peau":
        this.setState({ data: { ...data, peau: !data.peau } });
        break;
      case "ongles":
        this.setState({ data: { ...data, ongles: !data.ongles } });
        break;
      case "cheveux":
        this.setState({ data: { ...data, cheveux: !data.cheveux } });
        break;
      case "dents":
        this.setState({ data: { ...data, dents: !data.dents } });
        break;
      case "yeux":
        this.setState({ data: { ...data, yeux: !data.yeux } });
        break;
      case "muqueuses":
        this.setState({
          data: {
            ...data,
            muqueuses: !data.muqueuses,
            oculaire: false,
            auriculaire: false,
            nasale: false,
            buccale: false,
            pharyngée: false,
            pulmonaire: false,
            génitale: false,
            anale: false,
          },
        });
        break;

      case "oculaire":
        this.setState({ data: { ...data, oculaire: !data.oculaire } });
        break;
      case "auriculaire":
        this.setState({ data: { ...data, auriculaire: !data.auriculaire } });
        break;
      case "nasale":
        this.setState({ data: { ...data, nasale: !data.nasale } });
        break;
      case "buccale":
        this.setState({ data: { ...data, buccale: !data.buccale } });
        break;
      case "pharyngée":
        this.setState({ data: { ...data, pharyngée: !data.pharyngée } });
        break;
      case "pulmonaire":
        this.setState({ data: { ...data, pulmonaire: !data.pulmonaire } });
        break;
      case "génitale":
        this.setState({ data: { ...data, génitale: !data.génitale } });
        break;
      case "anale":
        this.setState({ data: { ...data, anale: !data.anale } });
        break;

      case "respisratoire":
        this.setState({
          data: { ...data, respisratoire: !data.respisratoire },
        });
        break;
      case "digestifs":
        this.setState({ data: { ...data, digestifs: !data.digestifs } });
        break;
      case "généraux":
        this.setState({ data: { ...data, généraux: !data.généraux } });
        break;
      case "neurologique":
        this.setState({ data: { ...data, neurologique: !data.neurologique } });
        break;

      case "otherInfos":
        this.setState({ data: { ...data, otherInfos: event_.target.value } });
        break;

      default:
        break;
    }
  }

  onSubmit() {
    this.props.handler(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { page } = this.props;
    const width = page.toString() + "%";
    return (
      <>
        <p className="pageTitle">Localisation de l'effet indésirable</p>
        <div className="lineVectors">
          <hr className="lineVector" style={{ width }} />
          <hr className="lineVectorFull" />
        </div>

        <Grid className="smallForm">
          <p className="robotoFontB">Sur la zone d'application du produit</p>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="zoneProduit" checked={data.zoneProduit} />
              {" Oui"}
            </label>
          </Grid>

          <p className="robotoFontB">
            Réaction à distance de la zone d'application
          </p>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="distanceProduit" checked={data.distanceProduit} />
              {" Oui"}
            </label>
          </Grid>

          <p className="robotoFontB">Zone corporelle concernée/touchée</p>

          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="peau" checked={data.peau} />
              {" Peau"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="ongles" checked={data.ongles} />
              {" Ongles"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="cheveux" checked={data.cheveux} />
              {" Cheveux"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="dents" checked={data.dents} />
              {" Dents"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="yeux" checked={data.yeux} />
              {" Yeux"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="muqueuses" checked={data.muqueuses} />
              {" Muqueuses"}
            </label>
          </Grid>
          {data.muqueuses ? (
            <>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="oculaire"
                  className="inputBox"
                  checked={data.oculaire}
                />
                <p id="oculaire">oculaire</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="auriculaire"
                  className="inputBox"
                  checked={data.auriculaire}
                />
                <p id="auriculaire">auriculaire</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="nasale"
                  className="inputBox"
                  checked={data.nasale}
                />
                <p id="nasale">nasale</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="buccale"
                  className="inputBox"
                  checked={data.buccale}
                />
                <p id="buccale">buccale</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="pharyngée"
                  className="inputBox"
                  checked={data.pharyngée}
                />
                <p id="pharyngée">pharyngée</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="pulmonaire"
                  className="inputBox"
                  checked={data.pulmonaire}
                />
                <p id="pulmonaire">pulmonaire</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="génitale"
                  className="inputBox"
                  checked={data.génitale}
                />
                <p id="génitale">génitale</p>
              </Grid>
              <Grid className="subBoxSmall" onClick={this.handleChange}>
                <Checkbox
                  size={"small"}
                  id="anale"
                  className="inputBox"
                  checked={data.anale}
                />
                <p id="anale">anale</p>
              </Grid>
            </>
          ) : null}

          <p className="robotoFontB">Signes d'accompagnement :</p>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="respisratoire" checked={data.respisratoire} />
              {" Respisratoire"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="digestifs" checked={data.digestifs} />
              {" Digestifs"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="généraux" checked={data.généraux} />
              {" Généraux"}
            </label>
          </Grid>
          <Grid className="formRow" onClick={this.handleChange}>
            <label className="robotoFont">
              <Checkbox id="neurologique" checked={data.neurologique} />
              {" Neurologique"}
            </label>
          </Grid>

          <TextField
            fullWidth
            id="otherInfos"
            type="text"
            value={data.otherInfos}
            onChange={this.handleChange}
            label="Si autre chose, précisez"
          />

          {data !== null ? (
            <Grid textAlign={"end"} marginBottom="24px" marginTop="24px">
              <Button
                variant="contained"
                color="blue"
                onClick={() => this.onSubmit()}
              >
                {"SUIVANT"}
              </Button>
            </Grid>
          ) : (
            <Grid textAlign={"end"} marginBottom="24px" marginTop="24px">
              <Button variant="contained" disabled>
                {"SUIVANT"}
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
