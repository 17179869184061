import HomeIcon from "@mui/icons-material/Home";
import { Grid, ThemeProvider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { Theme } from "../../styles/styles";

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <ThemeProvider theme={Theme}>
        <header className="headerApp">
          <Grid className="headerImputabilite">
            <div></div>

            <p className="headerText">Politique de confidentialité</p>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </Grid>
        </header>
        <Grid margin={"3em"}>
          <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
          <h4>1. INTRODUCTION</h4> VITAREGUL apporte le plus grand soin à la
          protection des données à caractère personnel qu'elle collecte et
          considère la protection des données à caractère personnel comme étant
          d'une importance fondamentale pour le fonctionnement de ses activités.
          Les données à caractère personnel que nous collectons sont protégées
          et traitées avec le plus grand soin, dans le respect de la
          règlementation en vigueur et en application des dispositions de la Loi
          n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
          aux libertés et du Règlement (UE) 2016/679 du Parlement européen et du
          Conseil du 27 avril 2016 relatif à la protection des personnes
          physiques à l'égard du traitement des données à caractère personnel et
          à la libre circulation de ces données, et abrogeant la directive
          95/46/CE (ci- après le « RGPD »). La présente politique de
          confidentialité s'adresse aux personnes extérieures à notre
          organisation avec lesquelles nous interagissons, y compris les
          visiteurs de notre site internet (ci-après les « Visiteurs ») et les
          utilisateurs de l'application VIGICARE et des services proposés par
          VITAREGUL à titre gratuit et/ou à titre payant via l'application
          (ci-après les « Utilisateurs »), ou des candidats (conjointement, «
          vous »). Dans le cadre de notre engagement à protéger vos données à
          caractère personnel de manière transparente, nous voulons vous
          informer :
          <br />
          - Des catégories de données à caractère personnel collectées et
          traitées par VITAREGUL ;
          <br />
          - De la manière et des finalités pour lesquelles VITAREGUL collecte et
          traite vos données à caractère personnel ;
          <br />
          - Du fondement légal en vertu duquel vos données à caractère personnel
          sont traitées ;
          <br />
          - Des catégories de destinataires de vos données à caractère personnel
          ;
          <br />- De vos droits et de nos obligations par rapport à un tel
          traitement.
          <h4>2. QUI EST LE RESPONSABLE DU TRAITEMENT DE VOS DONNÉES ?</h4>
          Lorsque l'application VIGICARE est utilisée par un particulier, le
          responsable du traitement de vos données à caractère personnel est la
          société VITAREGUL CONSULTING, Société par action simplifiée,
          enregistrée au RCS de MULHOUSE, sous le numéro 912 237 864, dont le
          siège social est situé 15 Rue des Sorbiers, 68510 Sierentz (ci-après
          désignée par « VITAREGUL » ou « nous », « nos »). Lorsque
          l'application VIGICARE est utilisée par un professionnel de santé,
          celui-ci agit en qualité de responsable de traitement et VITAREGUL
          agit en qualité de sous-traitant au sens du RGPD.
          <h4>3. QUELLES DONNÉES PERSONNELLES TRAITONS-NOUS ET POUR QUELLES</h4>
          FINALITÉS ? VITAREGUL collecte et traite uniquement les données
          personnelles vous concernant pour lesquelles elle a des bases légales.
          Les bases légales incluent votre consentement (lorsque vous avez donné
          votre accord pour le traitement de vos données), l'exécution d'un
          contrat (lorsque le traitement des données est nécessaire à
          l'exécution du contrat conclu entre VITAREGUL et vous-même), le
          respect d'une obligation légale, en particulier des dispositifs de
          vigilance sanitaire prévus par les dispositions du Code de la santé
          publique, et les “intérêts légitimes” de VITAREGUL. En tant que
          responsable de traitement, VITAREGUL collecte et traite les données
          suivantes, pour les finalités détaillées ci-après : Inscription sur
          l'application VIGICARE, gestion des comptes et des accès Utilisateurs:
          <br />
          - Données de connexion : adresse email, nom et prénom si ces
          informations sont collectées à partir de l'adresse email, support
          technique.
          <br />
          - Pour les Utilisateurs professionnels de santé : adresse email,
          qualité de professionnel de santé, profession.
          <br />
          - Pour les Utilisateurs Premium : données d'identification, données
          relatives à l'abonnement souscrit par l'Utilisateur, données
          financières afin de permettre le paiement de l'abonnement souscrit par
          l'Utilisateur. Abonnement à la newsletter :
          <br />
          - Adresse email de l'Utilisateur. Réalisation du test d'imputabilité
          : Lorsqu'un Utilisateur réalise un test d'imputabilité sur
          l'application VIGICARE, nous collectons les informations suivantes :
          <br />
          - Type de médicament ou de produit recherché par l'Utilisateur
          (médicament, dispositif médical, cosmétique, complément alimentaire) ;
          <br />
          - Nom et marque, fabricant du produit recherché, substances actives ;
          <br />
          - Sexe, tranche d'âge indiqués par l'Utilisateur lors de la recherche
          ;
          <br />
          - Date de prise du produit indiqué par l'Utilisateur lors de la
          recherche ;
          <br />
          - Date de l'effet indésirable indiqué par l'Utilisateur lors de la
          recherche, type d'effet indésirable, description de l'effet
          indésirable (optionnel), conséquences de l'effet indésirable si
          applicable : hospitalisation, incapacité fonctionnelle permanente ou
          temporaire, handicap, anomalies congénitales, risque vital immédiat ou
          décès. Déclaration d'un effet indésirable : Lorsqu'un Utilisateur
          utilise l'application VIGICARE pour déclarer la survenance d'un effet
          indésirable, nous collectons les informations suivantes, nécessaires
          pour préremplir la Déclaration d'effet indésirable susceptible d'être
          dû à un médicament ou produit mentionné à l'art. R.5121-150 du Code de
          la santé publique :
          <br />
          - Type de médicament ou de produit auquel l'effet indésirable est lié
          (médicament, dispositif médical, cosmétique, complément alimentaire) ;
          <br />
          - Données d'identification et de contact de la personne signalant
          l'effet indésirable : prénom, adresse email
          <br />
          - Pays de situation du déclarant ;
          <br />
          - Données indirectement identifiantes relatives à la personne exposée
          à l'effet indésirable : 3 premières lettres du nom de famille du
          patient traité, première lettre du prénom du patient traité, sexe,
          poids en kg, taille, date de naissance.
          <br />
          - Données de santé relatives à la personne exposée à l'effet
          indésirable : état de santé, antécédents médicaux, facteurs ayant pu
          favoriser la survenue de l'effet indésirable, état de grossesse,
          posologie, date de début et de fin du traitement, voie
          d'administration, numéro de lot, service hospitalier dans lequel le
          produit a été administré, pharmacie ayant délivré le produit.
          <br />
          - Informations relatives à l'effet indésirable déclaré : Date de
          survenue, département de survenue, durée de l'effet, description de
          l'effet, conséquences de l'effet indésirable, évolution de l'effet
          indésirable. Gestion de l'application et réalisation de mesures
          d'audience :
          <br />
          - Adresse IP, le type de navigateur, la langue, le système
          d'exploitation, les informations de l'appareil (y compris les
          identifiants de l'application et de l'appareil), la page Web de
          référence, les pages visitées, la localisation et les autres
          informations collectées par les Cookies (selon les autorisations que
          vous nous avez accordées) . Gestion des candidatures spontanées et
          des recrutements transmises via le site internet :
          <br />
          - Données d'identification : état civil, nom, prénom, âge / date de
          naissance.
          <br />
          - Données de contact : adresse email, adresse postale, numéro de
          téléphone.
          <br />
          - Données relatives à la candidature : poste occupé, niveau
          d'expérience, domaine d'activité, cursus professionnel et l'ensemble
          des données à caractère personnel figurant sur le CV, la lettre de
          motivation ou tout autre document auquel VITAREGUL aurait accès dans
          le cadre de la candidature. Gestion des demandes reçues via le
          formulaire de contact :
          <br />
          - Données d'identification : nom, prénom.
          <br />
          - Données de contact : adresse email.
          <br />- Sujet de votre demande, contenu de votre message. Le caractère
          obligatoire ou facultatif des données que vous renseignez vous est
          signalé lors de leur collecte.
          <h4>
            4. À QUELLES CATÉGORIES DE DESTINATAIRES VOS DONNÉES SONT-ELLES
          </h4>
          TRANSMISES ? Les données à caractère personnel que nous collectons
          nous sont destinées en notre qualité de responsable du traitement, ou
          de sous-traitant lorsque nous agissant pour le compte d'un
          professionnel de santé. Elles pourront être communiquées aux
          catégories de destinataires listées ci- dessous pour les finalités
          visées dans la présente politique de confidentialité. Ces opérations
          sont réalisées sur la base d'instruments conformes à la réglementation
          applicable et aptes à assurer la protection de vos données
          personnelles et le respect de votre vie privée. Ainsi, aux fins
          énoncées ci-dessus, VITAREGUL est susceptible de partager vos données
          à caractère personnel avec des tiers, mais uniquement dans les cas
          suivants :
          <br />
          - VITAREGUL est susceptible de faire appel à des prestataires de
          services, mandataires ou fournisseurs pour assurer des prestations
          techniques, notamment d'hébergement. Ces tiers doivent à tout moment
          garantir des niveaux de sécurité élevé à l'égard de vos données à
          caractère personnel et sont liés, le cas échéant, par un accord
          juridique en vertu duquel ils sont tenus de préserver la
          confidentialité et la sécurité de vos données à caractère personnel,
          et de les traiter uniquement selon les directives de VITAREGUL ;
          VITAREGUL ne transfère pas vos données en dehors de l'Espace
          Economique Européen (EEE). Toutefois, si cela devait arriver,
          VITAREGUL mettra en place des mécanismes contractuels et des processus
          juridiques contraignants pour légalement transférer les données
          personnelles au-delà des frontières de la zone EEE et sécuriser ces
          flux de données ;
          <br />
          - VITAREGUL est amenée à porter vos données personnelles à la
          connaissance de certains de ses collaborateurs, soumis également à une
          obligation de confidentialité, et dans le strict et seul cadre des
          finalités mentionnées ci-dessus ;
          <br />
          - En cas de fusion ou d'acquisition de VITAREGUL, en totalité ou en
          partie, par une autre société ou si VITAREGUL venait à vendre ou céder
          tout ou partie de ses activités, l'acquéreur aurait accès aux
          informations collectées par VITAREGUL, et notamment aux données à
          caractère personnel, sous réserve des lois en vigueur. De même, les
          données à caractère personnel peuvent être transférées dans le cadre
          d'une restructuration de l'entreprise, d'une procédure d'insolvabilité
          ou de tout autre événement similaire, si la législation applicable le
          permet et conformément à celle-ci ;
          <br />- VITAREGUL peut également divulguer vos données à caractère
          personnel lorsque la loi l'y oblige à des autorités légalement
          habilitées et ce pour satisfaire ses obligations légales,
          réglementaires ou conventionnelles, notamment à ses obligations en
          matière de vigilance sanitaire prévues par les dispositions du Code de
          la santé publique. L'Utilisateur est notamment informé que dans le
          cadre su service de déclaration d'un effet indésirable, ses données
          sont susceptibles d'être transmises au Centre régional de
          pharmacovigilance (CRPV) et/ou à l'Agence nationale de sécurité du
          médicament et des produits de santé (ANSM). Dans tous les cas,
          VITAREGUL fera ses meilleurs efforts pour assurer la confidentialité
          et la sécurité des données personnelles traitées lors de leur
          transmission aux entités précitées.
          <h4>5. POUR QUELLES DURÉES CONSERVONS-NOUS VOS DONNÉES ?</h4>
          Les durées de conservation que nous appliquons à vos données à
          caractère personnel sont limitées et proportionnées aux finalités pour
          lesquelles elles ont été collectées. La durée de conservation des
          données personnelles par nos services est variable et déterminée par
          différents critères, dont :
          <br />
          - la finalité pour laquelle nous les utilisons : VITAREGUL doit
          conserver les données pendant la période nécessaire à
          l'accomplissement de la finalité liée au traitement ; et
          <br />- les obligations légales : la législation ou la réglementation
          peut fixer une durée minimale pendant laquelle nous devons conserver
          les données personnelles. Nous organisons notre politique de
          conservation des données en fonction de ces critères et sommes à votre
          disposition pour répondre à vos éventuelles interrogations. Par
          ailleurs, vos données sont conservées jusqu'à ce que vous en demandiez
          la suppression. En cas de demande de suppression, toutes vos données
          seront définitivement supprimées dans les meilleurs délais à compter
          de la réception de votre demande, dans la mesure où cette suppression
          est autorisée par la règlementation en vigueur.
          <h4>6. DE QUELS DROITS DISPOSEZ-VOUS SUR VOS DONNÉES ?</h4>
          Votre droit à l'information - La présente politique de confidentialité
          vous informe de l'identité du responsable de traitement, des finalités
          et bases légales pour lesquelles vos données sont traitées, de la
          durée de conservation de vos données et des destinataires ou
          catégories de destinataires avec lesquels sont partagées vos données
          personnelles ainsi que de vos droits. Si nous décidons de traiter des
          données pour des finalités autres que celles indiquées, toutes les
          informations relatives à ces nouvelles finalités vous seront
          communiquées. Votre droit d'accès et à la rectification de vos données
          - Vous disposez du droit d'accéder aux données à caractère personnel
          vous concernant dont nous disposons. Vous pouvez également nous
          demander que vos données personnelles soient, selon les cas,
          rectifiées ou complétées si elles sont inexactes, incomplètes,
          équivoques ou périmées. Votre droit à l'effacement de vos données -
          Vous pouvez nous demander l'effacement de vos données personnelles
          dans les cas définis par la loi. Votre droit à la limitation du
          traitement de vos données - Vous pouvez demander la limitation du
          traitement de vos données personnelles dans les cas prévus par la loi.
          Votre droit de vous opposer au traitement de vos données - Vous avez
          le droit de vous opposer à un traitement de données vous concernant
          pour des raisons tenant à votre situation particulière. Néanmoins,
          l'exercice de ce droit ne sera pas possible lorsqu'il existe des
          motifs légitimes et impérieux pour le traitement de vos données au
          regard de la législation ou de la réglementation et notamment par
          exemple dans le cadre du respect de nos obligations en matière de
          cosmétovigilance ou pour la constatation, l'exercice ou la défense de
          droits en justice. Votre droit à la portabilité de vos données - Vous
          disposez du droit à la portabilité de vos données personnelles lorsque
          la loi le permet. A ce titre, vous pouvez nous demander de transférer
          vos données à un autre organisme ou de vous les communiquer. Votre
          droit de retirer votre consentement - Lorsque les traitements de
          données que nous mettons en œuvre sont fondés sur votre consentement,
          vous pouvez le retirer à n'importe quel moment. Nous cesserons alors
          de traiter vos données à caractère personnel sans que les opérations
          antérieures pour lesquelles vous aviez donné votre consentement ne
          soient remises en cause. Votre droit d'introduire un recours - Vous
          avez le droit d'introduire une réclamation auprès de la CNIL ou auprès
          de toute autre autorité de contrôle. Votre droit de définir des
          directives post-mortem - Vous avez la possibilité de définir des
          directives relatives à la conservation, à l'effacement et à la
          communication de vos données personnelles après votre décès et ce
          auprès d'un tiers de confiance, certifié et chargé de faire respecter
          la volonté du défunt conformément aux exigences du cadre juridique
          applicable. Les modalités d'exercice de vos droits - Tous les droits
          énumérés ci-avant peuvent être exercés à l'adresse email suivante
          contact@vigicare.io.
          <h4>7. COMMENT PROTÉGEONS-NOUS VOS DONNÉES PERSONNELLES ?</h4>
          Toutes les précautions utiles sont prises pour assurer la sécurité et
          la confidentialité de vos données personnelles, notamment pour
          empêcher leur perte, altération, destruction ou utilisation par des
          tiers non autorisés. Nous suivons les normes généralement acceptées, y
          compris l'utilisation de protections administratives, physiques et
          techniques appropriées, pour protéger les données personnelles qui
          nous sont soumises et mettons en place des mesures de sécurité
          techniques et opérationnelles adéquates. Ces mesures tiennent compte
          de la sensibilité des données personnelles que nous collectons,
          traitons et stockons et de l'état actuel de la technologie. En outre,
          nous demandons à nos prestataires et sous-traitants pouvant avoir
          accès aux données personnelles, de mettre en place des mesures de
          sécurité techniques et organisationnelles appropriées à l'égard de ces
          données. De plus, les collaborateurs de VITAREGUL qui auraient accès à
          vos données à caractère personnel en raison de leurs attributions,
          sont soumis à la plus grande confidentialité à cet égard. Cependant,
          bien que nous nous efforcions d'utiliser des moyens raisonnablement
          acceptables pour protéger vos données personnelles, nous ne pouvons
          pas garantir leur sécurité ou leur confidentialité absolue, mais nous
          garantissons de faire tous les efforts raisonnables pour éviter tout
          abus ou perte.
          <h4>8. COOKIES</h4>
          Sous réserve de votre consentement lorsque la loi l'exige,
          l'Application utilise des cookies, des traceurs et des technologies
          similaires (ci-après les « Cookies »). Les Cookies sont des petits
          fichiers textes stockés sur le disque dur de votre terminal
          (ordinateur, tablette, mobile) à l'occasion de la consultation d'un
          service en ligne. Les Cookies remplissent différentes fonctions, et
          notamment vous permettent de naviguer entre les pages avec efficacité,
          se souviennent de vos préférences et permettent l'affichage de
          publicités.
          <h5>8.1.POURQUOI UTILISONS-NOUS DES COOKIES ?</h5>
          Nous utilisons des Cookies afin de faciliter et d'améliorer la
          navigation sur l'Application (par exemple en se rappelant les
          paramètres des préférences des utilisateurs). Les Cookies peuvent
          également être utilisés pour contribuer à accélérer vos futures
          visites et expériences sur l'Application (par exemple en conservant
          vos identifiants de connexion). Nous utilisons également des Cookies
          pour collecter des statistiques anonymes et globales, qui nous
          permettent de comprendre comment les utilisateurs exploitent
          l'Application et en améliorer la structure et le contenu. Lorsque les
          Cookies que nous utilisons traitent vos données personnelles, ce
          traitement est effectué conformément à la règlementation applicable à
          la protection des données personnelles.
          <h5>
            8.2.QUELS SONT LES DIFFERENTS TYPES DE COOKIES QUE NOUS UTILISONS ?
          </h5>
          L'application VIGICARE utilise différents types de Cookies qui
          nécessitent votre consentement avant que le Cookie ne soit installé
          sur votre terminal, à l'exception des Cookies techniques. Vous
          trouverez ci-dessous une liste des différents types de Cookies que
          nous utilisons sur l'application.
          <br />
          a. Cookies techniques
          <br />
          Les Cookies techniques, sont nécessaires au bon fonctionnement de
          l'application et à l'utilisation de ses fonctionnalités. Sans ces
          Cookies, l'application ne fonctionnerait pas aussi efficacement que
          nous le souhaiterions et nous risquons de ne pas pouvoir mettre
          l'application à votre disposition, ni fournir certaines
          fonctionnalités ou certains services demandés. Il s'agit par exemple
          des traceurs permettant de conserver le choix exprimé par les
          Utilisateurs sur le dépôt de Cookies, y compris ceux visant à assurer
          la sécurité du mécanisme d'authentification. Leur dépôt n'est pas
          soumis à votre consentement.
          <br />
          b. Cookies de préférence
          <br />
          Nous utilisons également des Cookies de préférence qui permettent à
          l'application de se souvenir de vos choix (tels que vos données
          d'identification, votre langue ou votre pays) à des fins de
          personnalisation.
          <br />
          c. Cookies d'analyse
          <br />
          L'application peut également utiliser des Cookies d'analyse et de
          mesures d'audience (par exemple Google Analytics). Ces Cookies
          collectent des informations sur la façon dont vous utilisez et
          naviguez sur l'application. À titre d'exemple, ces Cookies garderont
          en mémoire les pages les plus consultées, les pages de renvoi/sortie,
          l'horodatage et le parcours de navigation. Nous utilisons ces
          informations pour analyser les tendances, gérer l'application ou
          suivre le parcours de navigation des Utilisateurs sur l'application
          afin de l'améliorer.
          <br />
          d. Cookies publicitaires / Cookies de tiers <br />
          Des Cookies peuvent être déposés sur votre navigateur, avec votre
          consentement, par nos partenaires agissant en qualité de responsables
          de traitements en vue de proposer leurs services. Ces tiers placent
          des Cookies sur votre appareil lorsque vous consultez l'application en
          mode gratuit notamment à des fins de publicité ciblée ou à l'occasion
          de l'intégration sur l'application de certains modules et
          fonctionnalités. Vous trouverez davantage d'informations concernant
          ces Cookies dans la politique de confidentialité de ces tiers.
          <br />
          Vous pouvez consulter la liste de ces partenaires et contrôler le
          dépôt de leurs Cookies sur votre navigateur ci-dessous.
          <br />
          Vous trouverez ci-dessous une liste détaillée des différents types de
          Cookies présents sur l'application :
          <br />
          <br />
          Domaine : vigicare.io
          <ul>
            <li>
              Nom du Cookie : jwtToken
              <br />
              Catégorie du Cookie : Fonctionnel
              <br />
              Finalité : Authentification
              <br />
              Durée d'expiration : 12 h
            </li>
          </ul>
          <h5>8.3.COMBIEN DE TEMPS LES COOKIES SONT-ILS CONSERVÉS ?</h5>
          Les Cookies font l'objet d'un délai maximum de conservation de treize
          (13) mois après leur installation initiale sur le terminal de
          l'Utilisateur, tel que l'exige le droit applicable.
          <h5>8.4. COMMENT CONTRÔLER LES COOKIES SUR NOTRE APPLICATION ?</h5>
          Lors de votre arrivée sur l'application, une interface de gestion vous
          informe de la présence de Cookies et vous invite à indiquer vos choix
          et d'accepter / de refuser chaque catégorie de Cookies. Les Cookies ne
          sont déposés que si vous les acceptez, à l'exception des Cookies
          fonctionnels nécessaires au fonctionnement de l'application. Vous
          pouvez également gérer, désactiver et autoriser les Cookies en
          configurant vos paramètres de navigation directement depuis votre
          navigateur.
          <h4>9. DES QUESTIONS ?</h4>
          Si vous avez des questions concernant la présente politique de
          confidentialité ou nos pratiques en matière de protection des données
          à caractère personnel, veuillez nous contacter en nous adressant un
          e-mail à l'adresse indiquée ci-dessus.
          <h4>10.MISE À JOUR</h4>
          Nous nous réservons le droit de modifier la présente politique afin de
          tenir compte des changements apportés aux diverses réglementations et
          pratiques en vigueur. Les changements que nous apporterons à notre
          politique seront directement publiés sur la page dédiée de notre
          application. Si nous apportons des modifications à la présente
          politique de confidentialité qui affectent de manière substantielle la
          manière dont nous traitons vos données personnelles, nous vous
          contacterons par tout moyen de communication pour vous en informer.
          <br />
          <br />
          Date de la dernière mise à jour: avril 2023
        </Grid>
      </ThemeProvider>
    );
  }
}
