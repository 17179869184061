import adverseEffect from "./adverseEffect.json";
import app from "./app.json";
import auth from "./auth.json";
import home from "./home.json";
import premium from "./premium.json";
import productSearch from "./productSearch.json";
import profile from "./profile.json";

const fr = {
  app,
  adverseEffect,
  auth,
  home,
  premium,
  profile,
  productSearch,
};

export default fr;
